import { useCallback, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import { Button, Link as ChakraLink, Stack, Text, useToast } from "@chakra-ui/react"
import { FEEDBACK_PROMPT } from "common-utils"
import { ChatGptMessageRole } from "rikeimatch-graphql"

import InterviewInput from "~/components/organisms/InterviewInput"
import InterviewMessages from "~/components/organisms/InterviewMessages"
import {
  useJobSeekerAiInterviewAvailabilityQuery,
  useJobSeekerApplyJobInterviewMutation,
  useJobSeekerShowJobQuery,
} from "~/generated/graphql"
import useChatConversation from "~/hooks/useChatConversation"

import type { ChatGptMessageInputSource } from "rikeimatch-graphql"

const MAX_ANSWERS = 5

const JobInterview = () => {
  const { jobId } = useParams()
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const navigation = useNavigate()
  const toast = useToast()

  const {
    startJobInterview,
    sendUserMessage,
    loading,
    answer,
    messages,
    hasStarted,
    retry,
    jobInterview,
  } = useChatConversation()

  const { data: jobData } = useJobSeekerShowJobQuery({
    variables: {
      id: jobId || "",
    },
    skip: !jobId,
  })
  const job = jobData?.job
  const company = job?.company

  const { data: availabilityData } = useJobSeekerAiInterviewAvailabilityQuery({
    variables: { jobId: jobId || "" },
    skip: !jobId,
  })
  const availability = availabilityData?.aiInterviewAvailability
  const isAvailable = (availability?.availableJobInterviewsCount || 0) > 0
  const isReady = availability?.incompleteProfileFieldErrors.length === 0
  const profileErrors = availability?.incompleteProfileFieldErrors || []

  const [applyJobInterview] = useJobSeekerApplyJobInterviewMutation()

  const uniqueUserContents = new Set(
    messages.filter((m) => m.role === ChatGptMessageRole.User).map((m) => m.content),
  )
  const hasFinished = uniqueUserContents.size > MAX_ANSWERS

  const handleSubmit = (input: string, inputSource: ChatGptMessageInputSource) => {
    void sendUserMessage(input, inputSource)
  }

  const hideFooter = useCallback(() => {
    const footer = document.getElementById("footer")
    if (footer) {
      footer.style.display = "none"
    }
  }, [])

  const handleApply = useCallback(async () => {
    setHasSubmitted(true)
    await applyJobInterview({
      variables: {
        input: {
          jobInterviewId: jobInterview?.id || "",
        },
      },
    })
    toast({
      title: "送信しました",
      status: "success",
      duration: 9000,
      isClosable: true,
    })
  }, [applyJobInterview, jobInterview?.id, toast])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (hasFinished) {
      void sendUserMessage(FEEDBACK_PROMPT)
    }
  }, [hasFinished])

  return (
    <Stack
      sx={{
        h: "calc(100vh - 64px)",
        pt: 4,
        pb: 8,
        px: 4,
        mx: "auto",
        position: "relative",
      }}
      spacing={10}
    >
      <Stack sx={{ w: { base: "100%", md: "xl" }, display: "flex", h: "100%", mx: "auto" }}>
        {!hasStarted && (
          <Stack sx={{ flexGrow: 1, justifyContent: "center" }} spacing={4}>
            <Stack sx={{ whiteSpace: "pre-wrap" }}>
              <Text fontSize={{ base: "medium", md: "large", whiteSpace: "pre-wrap" }}>
                {company?.name}の募集「{job?.title}」に基づき、AI面接診断を行います。
              </Text>
              <Text fontSize={{ base: "medium", md: "large", whiteSpace: "pre-wrap" }}>
                AIによる模擬面接を行います。複数の質問にチャット形式で回答後、面接結果に対するフィードバックが表示されます。
              </Text>
              <Text fontSize={{ base: "medium", md: "large", whiteSpace: "pre-wrap" }}>
                その後、「企業に面接結果を提出して応募」「提出せず終了」を最後に選択できます！提出することであなただけの特別な選考案内が届くかもしれません。
                {"\n"}
                ※提出しない限り、あなたが受験したことが企業に伝わることはありません。気軽に模擬面接として受験してみてください！
                {"\n"}
                ※企業ごとのAI模擬面接は1日3社（1社あたり1回）が上限です
                {"\n"}
                AI面接に関する詳細は下記にもまとめましたので、気になる際にはご参考ください。
              </Text>
              <ChakraLink
                isExternal
                href="https://useful-radio-75c.notion.site/AI-0b62765b8a214c2cad379bb052763d10"
              >
                https://useful-radio-75c.notion.site
              </ChakraLink>
            </Stack>
            <Button
              onClick={async () => {
                if (!jobId) {
                  return
                }

                await startJobInterview({ jobId })
                hideFooter()
              }}
              isDisabled={!isReady || !isAvailable}
            >
              開始する
            </Button>
            {!isAvailable && <Text>1日の使用回数を超えました。明日またお試しください。</Text>}
            {!isReady && (
              <Stack>
                <Text>
                  AI面接診断
                  を使用するには、プロフィール設定が完了している必要があります。下記の項目を入力してください。
                </Text>
                <Stack sx={{ bgColor: "blue.100", p: 2 }}>
                  {profileErrors.map((error) => (
                    <Text key={error}>{error}</Text>
                  ))}
                </Stack>
                <Link to="/profile">
                  <Button>プロフィール設定へ</Button>
                </Link>
              </Stack>
            )}
            {availability && availability.availableForJob === false && !hasStarted && (
              <Stack>
                <Text>※ すでにAI面接の結果を提出済みです。練習のみ可能です。</Text>
              </Stack>
            )}
          </Stack>
        )}
        {hasStarted && (
          <InterviewMessages messages={messages} streamingAnswer={loading ? answer : undefined} />
        )}
        {hasStarted && (
          <InterviewInput
            handleSubmit={handleSubmit}
            handleRetry={retry}
            hasFinished={hasFinished}
            loading={loading}
          />
        )}
        {hasFinished && !loading && !hasSubmitted && availability?.availableForJob && (
          <Stack>
            <Text>
              AI面接の内容を企業に提出できます。その内容を踏まえて面談・説明会やあなただけの特別な案内が届く可能性があります！
            </Text>
            <Stack
              sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", my: 2 }}
            >
              <Button onClick={handleApply} colorScheme="blue">
                企業に面談結果を送信する
              </Button>
              <Button onClick={() => navigation(-1)}>終了し、前の画面へ戻る</Button>
            </Stack>
          </Stack>
        )}
        {hasSubmitted ||
          (hasFinished && !availability?.availableForJob && (
            <Stack>
              <Text>AI面接の内容を企業に提出しました。</Text>
              <Stack
                sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", my: 2 }}
              >
                <Button onClick={() => navigation(-1)}>終了し、前の画面へ戻る</Button>
              </Stack>
            </Stack>
          ))}
      </Stack>
    </Stack>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default JobInterview
