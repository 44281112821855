import React, { type ReactNode } from "react"
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom"

import { InterviewVersion } from "rikeimatch-graphql"

import { Footer, Header } from "~/components/organisms"

import { LoadingOverlay } from "./components/molecules"
import CreateReferralComments from "./components/templates/CreateReferralComments"
import JobInterview from "./components/templates/JobInterview"
import MemberDetail from "./components/templates/MemberDetail"
import ProductDetail from "./components/templates/ProductDetail"
import Settings from "./components/templates/Settings"
import Interview from "./components/templates/interviews/Interview"
import { useUser } from "./containers"
import CampaignProvider from "./containers/CampaignProvider"
import UnreadMessagesProvider from "./containers/UnreadMessagesProvider"
import { useJobSeekerMeQuery } from "./generated/graphql"

const CompanyDetails = React.lazy(() => import("./components/templates/CompanyDetails"))
const CreateProfile = React.lazy(() => import("./components/templates/CreateProfile"))
const EditProfile = React.lazy(() => import("./components/templates/EditProfile"))
const JobDetails = React.lazy(() => import("./components/templates/JobDetails"))
const Likes = React.lazy(() => import("./components/templates/Likes"))
const Messaging = React.lazy(() => import("./components/templates/Messaging"))
const ProfileTips = React.lazy(() => import("./components/templates/ProfileTips"))
const Contents = React.lazy(() => import("./components/templates/Contents"))
const Top = React.lazy(() => import("./components/templates/top/Top"))
const TopAd = React.lazy(() => import("./components/templates/top/TopAd"))
const FeaturedJobs = React.lazy(() => import("./components/templates/FeaturedJobs"))
const FeatureTopics = React.lazy(() => import("./components/templates/FeatureTopics"))
const Rikelink = React.lazy(() => import("./components/templates/Rikelink"))

// 非ログイン時でも表示したいパス
const relevantPaths = ["/jobs/", "/company/", "/topics", "/members/", "/products/"]

// 該当パスの場合、ログイン状態かつ必須プロフィール登録済みか確認
// 該当パスの場合、
const Private: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const location = useLocation()
  const { user, loading } = useUser()
  const { data, loading: loadingJobSeeker } = useJobSeekerMeQuery()
  if (loading || loadingJobSeeker) {
    return <LoadingOverlay />
  }

  // 現在のパスが非ログイン状態でも表示したいパスに含まれているか確認
  const isRelevantPath = relevantPaths.some((path) => location.pathname.includes(path))

  // 非表示にするパスの場合、ログイン状態でない場合は表示する
  if (!user && isRelevantPath) {
    return children
  }

  // プロフィール画面は遷移しない
  if (location.pathname === "/profile") {
    return (
      <UnreadMessagesProvider>
        <CampaignProvider>{children}</CampaignProvider>
      </UnreadMessagesProvider>
    )
  }

  // プロフィールの必須項目を埋めていない場合は、新規登録画面へ遷移
  const isRegisteredRequiredProfile = data?.jobSeekerMe?.isRegisteredRequiredProfile
  if (!isRegisteredRequiredProfile && location.pathname !== "/profile/new") {
    return <Navigate to="/profile/new" />
  }

  return user ? (
    <UnreadMessagesProvider>
      <CampaignProvider>{children}</CampaignProvider>
    </UnreadMessagesProvider>
  ) : (
    <Navigate to="/" />
  )
}

const Layout = () => (
  <>
    <Header />
    <Outlet />
    <Footer />
  </>
)

const AppRoutes = (): ReactNode => (
  <React.Suspense fallback={<LoadingOverlay />}>
    <Routes>
      <Route element={<Layout />}>
        <Route path="/">
          <Route index element={<Top variant="normal" />} />
          <Route path="ad" element={<Top variant="normal" />} />
          <Route path="ad_form" element={<TopAd />} />
          <Route path="information" element={<Top variant="normal" />} />
          <Route path="student/basic/">
            <Route path="*" element={<Top variant="intern" />} />
          </Route>
          <Route path="mendan" element={<Top variant="interview" />} />
          {/* <Route path="jobs" element={Home} /> */}
          <Route
            path="likes"
            element={
              <Private>
                <Likes />
              </Private>
            }
          />
          <Route
            path="jobs/:id"
            element={
              <Private>
                <JobDetails />
              </Private>
            }
          />
          <Route
            path="company/:id"
            element={
              <Private>
                <CompanyDetails />
              </Private>
            }
          />
          <Route
            path="/topics"
            element={
              <Private>
                <FeatureTopics />
              </Private>
            }
          />
          <Route
            path="topics/:slug"
            element={
              <Private>
                <FeaturedJobs />
              </Private>
            }
          />
          <Route
            path="/members/:id"
            element={
              <Private>
                <MemberDetail />
              </Private>
            }
          />
          <Route
            path="/products/:id"
            element={
              <Private>
                <ProductDetail />
              </Private>
            }
          />

          <Route
            path="messages"
            element={
              <Private>
                <Messaging />
              </Private>
            }
          />
          <Route
            path="messages/:threadId"
            element={
              <Private>
                <Messaging />
              </Private>
            }
          />
          <Route path="profile">
            <Route
              index
              element={
                <Private>
                  <EditProfile />
                </Private>
              }
            />
            <Route
              path="new"
              element={
                <Private>
                  <CreateProfile />
                </Private>
              }
            />
            <Route
              path="tips"
              element={
                <Private>
                  <ProfileTips />
                </Private>
              }
            />
          </Route>

          <Route
            path="settings"
            element={
              <Private>
                <Settings />
              </Private>
            }
          />

          <Route
            path="rikelink"
            element={
              <Private>
                <Rikelink />
              </Private>
            }
          />

          <Route
            path="contents"
            element={
              <Private>
                <Contents />
              </Private>
            }
          />

          <Route
            path="interview"
            element={
              <Private>
                <Interview version={InterviewVersion.V3} />
              </Private>
            }
          />

          <Route
            path="interview/jobs/:jobId"
            element={
              <Private>
                <JobInterview />
              </Private>
            }
          />

          {/* fallback */}
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Route>
      <Route path="rikelink/:slug" element={<Rikelink />} />
      <Route path="rikelink/:slug/referral_comments/new" element={<CreateReferralComments />} />
    </Routes>
  </React.Suspense>
)

// biome-ignore lint/style/noDefaultExport: ...
export default AppRoutes
