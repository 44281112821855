export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An ISO 8601-encoded date */
  ISO8601Date: string
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string
}

/** Autogenerated input type of AcceptJobInterview */
export type AcceptJobInterviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>
  jobInterviewId: Scalars["ID"]
}

export type Achievement = {
  __typename?: "Achievement"
  attachment?: Maybe<Scalars["String"]>
  date: Scalars["ISO8601Date"]
  description: Scalars["String"]
  id: Scalars["ID"]
  title: Scalars["String"]
  url?: Maybe<Scalars["String"]>
}

export type AchievementInput = {
  attachment?: InputMaybe<Scalars["String"]>
  date: Scalars["ISO8601Date"]
  description: Scalars["String"]
  title: Scalars["String"]
  url?: InputMaybe<Scalars["String"]>
}

export type AiInterviewAvailability = {
  __typename?: "AiInterviewAvailability"
  availableForJob: Scalars["Boolean"]
  availableInterviewsCount: Scalars["Int"]
  availableJobInterviewsCount: Scalars["Int"]
  incompleteProfileFieldErrors: Array<Scalars["String"]>
}

export type AiInterviewHistory = {
  __typename?: "AiInterviewHistory"
  chatGptThread: ChatGptThread
  chatGptThreadId: Scalars["ID"]
  createdAt: Scalars["ISO8601DateTime"]
  id: Scalars["ID"]
}

/** Autogenerated input type of ApplyJobInterview */
export type ApplyJobInterviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>
  jobInterviewId: Scalars["ID"]
}

/** Associate social account input */
export type AssociateSocialAccountInput = {
  provider: Scalars["String"]
  socialId: Scalars["ID"]
}

export enum AvailableJobTargetGrade {
  /** all */
  All = "all",
  /** graduation_2025 */
  Graduation_2025 = "graduation_2025",
  /** graduation_2026 */
  Graduation_2026 = "graduation_2026",
  /** graduation_2027 */
  Graduation_2027 = "graduation_2027",
  /** graduation_2028 */
  Graduation_2028 = "graduation_2028",
}

export type BooleanResult = {
  __typename?: "BooleanResult"
  success: Scalars["Boolean"]
}

export type Campaign = {
  __typename?: "Campaign"
  code: Scalars["String"]
  endsAt: Scalars["ISO8601DateTime"]
  id: Scalars["ID"]
  name: Scalars["String"]
  startsAt: Scalars["ISO8601DateTime"]
  viewed: Scalars["Boolean"]
}

export type ChatGptMessage = {
  __typename?: "ChatGptMessage"
  content: Scalars["String"]
  createdAt: Scalars["ISO8601DateTime"]
  id: Scalars["ID"]
  inputSource: ChatGptMessageInputSource
  role: ChatGptMessageRole
}

export type ChatGptMessageInput = {
  content: Scalars["String"]
  inputSource?: InputMaybe<ChatGptMessageInputSource>
  role: ChatGptMessageRole
}

export enum ChatGptMessageInputSource {
  /** text */
  Text = "text",
  /** unknown */
  Unknown = "unknown",
  /** voice */
  Voice = "voice",
}

export enum ChatGptMessageRole {
  /** assistant */
  Assistant = "assistant",
  /** system */
  System = "system",
  /** user */
  User = "user",
}

export type ChatGptResponse = {
  __typename?: "ChatGptResponse"
  answer: Scalars["String"]
}

export type ChatGptThread = {
  __typename?: "ChatGptThread"
  createdAt: Scalars["ISO8601DateTime"]
  id: Scalars["ID"]
  jobSeeker?: Maybe<JobSeeker>
  kind: ChatGptThreadKind
  messages: Array<ChatGptMessage>
}

export enum ChatGptThreadKind {
  /** focus_detail_generation */
  FocusDetailGeneration = "focus_detail_generation",
  /** interview_practice */
  InterviewPractice = "interview_practice",
  /** job_interview */
  JobInterview = "job_interview",
}

export type Companies = {
  __typename?: "Companies"
  data: Array<Company>
  pagination: Pagination
}

export type Company = {
  __typename?: "Company"
  applicationCount?: Maybe<Scalars["Int"]>
  businessCategories: Array<Scalars["String"]>
  businessDescription?: Maybe<Scalars["String"]>
  companyStaffs: Array<CompanyStaff>
  corporatePhilosophy?: Maybe<Scalars["String"]>
  coverImage?: Maybe<Scalars["String"]>
  email: Scalars["String"]
  employeesNumber?: Maybe<Scalars["Int"]>
  expiresOn?: Maybe<Scalars["ISO8601Date"]>
  foundedOn?: Maybe<Scalars["String"]>
  headquartersAddress?: Maybe<Scalars["String"]>
  homepage?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  industry?: Maybe<Scalars["String"]>
  jobs: Array<Job>
  likeCount?: Maybe<Scalars["Int"]>
  locations: Array<Scalars["String"]>
  logo?: Maybe<Scalars["String"]>
  members: Array<Member>
  name: Scalars["String"]
  offices?: Maybe<Scalars["String"]>
  prefecture?: Maybe<Scalars["String"]>
  products: Array<Product>
  representative?: Maybe<Scalars["String"]>
  scoutCount?: Maybe<Scalars["Int"]>
  sentJobLikeCount?: Maybe<Scalars["Int"]>
  sentScoutCount?: Maybe<Scalars["Int"]>
  workStyleAttraction?: Maybe<Scalars["String"]>
}

export type CompanyStaff = {
  __typename?: "CompanyStaff"
  company: Company
  companyId: Scalars["ID"]
  email: Scalars["String"]
  firstName: Scalars["String"]
  fullName: Scalars["String"]
  id: Scalars["ID"]
  lastName: Scalars["String"]
}

/** Autogenerated input type of CreateAiInterviewHistory */
export type CreateAiInterviewHistoryInput = {
  chatGptThreadId: Scalars["ID"]
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>
}

/** Create chat gpt message input */
export type CreateChatGptMessageInput = {
  content: Scalars["String"]
  systemContent?: InputMaybe<Scalars["String"]>
  threadId?: InputMaybe<Scalars["ID"]>
}

/** Autogenerated input type of CreateChatGptThread */
export type CreateChatGptThreadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>
  kind: ChatGptThreadKind
  systemContent: Scalars["String"]
  version?: InputMaybe<InterviewVersion>
}

/** Create company input */
export type CreateCompanyInput = {
  email: Scalars["String"]
  firstName: Scalars["String"]
  lastName: Scalars["String"]
  likeCount: Scalars["Int"]
  likeExpiresOn: Scalars["String"]
  name: Scalars["String"]
  scoutCount: Scalars["Int"]
  scoutExpiresOn: Scalars["String"]
}

/** Create company staff input */
export type CreateCompanyStaffInput = {
  companyId: Scalars["ID"]
  email: Scalars["String"]
  firstName: Scalars["String"]
  lastName: Scalars["String"]
}

/** Create featured job input */
export type CreateFeaturedJobInput = {
  featureTopicId: Scalars["ID"]
  jobId: Scalars["ID"]
}

/** Create job application status input */
export type CreateJobApplicationStatusInput = {
  jobApplicationId: Scalars["ID"]
  kind: JobApplicationStatusKind
}

/** Create job characteristic input */
export type CreateJobCharacteristicInput = {
  name: Scalars["String"]
}

/** Create job input */
export type CreateJobInput = {
  coverImage?: InputMaybe<Scalars["String"]>
  description: Scalars["String"]
  idealCandidate: Scalars["String"]
  image1?: InputMaybe<Scalars["String"]>
  image2?: InputMaybe<Scalars["String"]>
  image3?: InputMaybe<Scalars["String"]>
  images?: InputMaybe<Array<Scalars["String"]>>
  isFeatureable: Scalars["Boolean"]
  jobCharacteristicLabels?: InputMaybe<Array<JobCharacteristicLabelInput>>
  kind: JobKind
  locations?: InputMaybe<Array<Scalars["String"]>>
  members?: InputMaybe<Array<JobMemberInput>>
  paragraphs?: InputMaybe<Array<JobParagraphInput>>
  productImages?: InputMaybe<Array<Scalars["String"]>>
  productInfo?: InputMaybe<Scalars["String"]>
  status: JobStatus
  syncWithCompanyLocations?: Scalars["Boolean"]
  targetGrades?: InputMaybe<Array<AvailableJobTargetGrade>>
  title: Scalars["String"]
}

/** Autogenerated input type of CreateJobInterview */
export type CreateJobInterviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>
  jobId: Scalars["ID"]
}

export type CreateJobLikeInput = {
  interestLevel?: InputMaybe<JobLikeInterestLevel>
  jobId: Scalars["ID"]
}

/** Create job seeker search condition input */
export type CreateJobSeekerSearchConditionInput = {
  name: Scalars["String"]
  query: JobSeekerSearchConditionQueryInput
}

/** Create member input */
export type CreateMemberInput = {
  avatar?: InputMaybe<Scalars["String"]>
  bio?: InputMaybe<Scalars["String"]>
  jobTitle: Scalars["String"]
  messageToStudents?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
}

/** Create message template input */
export type CreateMessageTemplateInput = {
  kind: MessageTemplateKind
  name: Scalars["String"]
  text: Scalars["String"]
}

/** Create product input */
export type CreateProductInput = {
  description: Scalars["String"]
  image?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
}

/** Create recruiter note input */
export type CreateRecruiterNoteInput = {
  comment: Scalars["String"]
  jobSeekerId: Scalars["ID"]
}

/** Create referral comment input */
export type CreateReferralCommentInput = {
  avatar?: InputMaybe<Scalars["String"]>
  comment: Scalars["String"]
  jobSeekerId: Scalars["ID"]
  name: Scalars["String"]
  organization: Scalars["String"]
  relationship: Scalars["String"]
}

/** Create user campaign view input */
export type CreateUserCampaignViewInput = {
  campaignId: Scalars["ID"]
}

/** Autogenerated input type of DeleteAiInterviewHistory */
export type DeleteAiInterviewHistoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
}

export type EducationHistory = {
  __typename?: "EducationHistory"
  course: Scalars["String"]
  department: Scalars["String"]
  faculty: Scalars["String"]
  fullName: Scalars["String"]
  grade: EducationHistoryGrade
  graduatedOn?: Maybe<Scalars["String"]>
  highSchool?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  major: Scalars["String"]
  name: Scalars["String"]
  researchDetail?: Maybe<Scalars["String"]>
  researchSubject?: Maybe<Scalars["String"]>
}

export enum EducationHistoryGrade {
  /** b1 */
  B1 = "b1",
  /** b2 */
  B2 = "b2",
  /** b3 */
  B3 = "b3",
  /** b4 */
  B4 = "b4",
  /** b5 */
  B5 = "b5",
  /** b6 */
  B6 = "b6",
  /** c1 */
  C1 = "c1",
  /** c2 */
  C2 = "c2",
  /** c3 */
  C3 = "c3",
  /** c4 */
  C4 = "c4",
  /** d1 */
  D1 = "d1",
  /** d2 */
  D2 = "d2",
  /** d3 */
  D3 = "d3",
  /** d4 */
  D4 = "d4",
  /** graduated */
  Graduated = "graduated",
  /** m1 */
  M1 = "m1",
  /** m2 */
  M2 = "m2",
  /** s1 */
  S1 = "s1",
  /** s2 */
  S2 = "s2",
  /** s3 */
  S3 = "s3",
  /** s4 */
  S4 = "s4",
  /** s5 */
  S5 = "s5",
}

/** Education history input */
export type EducationHistoryInput = {
  department: Scalars["String"]
  faculty: Scalars["String"]
  grade: EducationHistoryGrade
  graduatedOn: Scalars["String"]
  highSchool?: InputMaybe<Scalars["String"]>
  major: Scalars["String"]
  name: Scalars["String"]
  researchDetail?: InputMaybe<Scalars["String"]>
  researchSubject?: InputMaybe<Scalars["String"]>
}

export type EducationalInstitution = {
  __typename?: "EducationalInstitution"
  id: Scalars["String"]
  name: Scalars["String"]
}

export type FeatureTopic = {
  __typename?: "FeatureTopic"
  description: Scalars["String"]
  id: Scalars["ID"]
  jobs: Array<Job>
  slug: Scalars["String"]
  title: Scalars["String"]
}

export type FeatureTopics = {
  __typename?: "FeatureTopics"
  data: Array<FeatureTopic>
  pagination: Pagination
}

export type FeaturedJob = {
  __typename?: "FeaturedJob"
  id: Scalars["ID"]
  job: Job
}

export type FeaturedJobs = {
  __typename?: "FeaturedJobs"
  data: Array<FeaturedJob>
  pagination: Pagination
}

/** Search input */
export type FilterInput = {
  /** Field name */
  field?: InputMaybe<Scalars["String"]>
  /** Value */
  value: Scalars["String"]
}

export type InterviewTemplate = {
  __typename?: "InterviewTemplate"
  answer: Scalars["String"]
  createdAt: Scalars["ISO8601DateTime"]
  evaluation: Scalars["String"]
  id: Scalars["ID"]
  question: Scalars["String"]
  updatedAt: Scalars["ISO8601DateTime"]
}

export enum InterviewVersion {
  /** v2 */
  V2 = "v2",
  /** v3 */
  V3 = "v3",
}

export type Job = {
  __typename?: "Job"
  accepted?: Maybe<Scalars["Boolean"]>
  characteristicLabels: Array<JobCharacteristicLabel>
  company: Company
  companyId: Scalars["ID"]
  coverImage?: Maybe<Scalars["String"]>
  description: Scalars["String"]
  descriptionText: Scalars["String"]
  id: Scalars["ID"]
  idealCandidate: Scalars["String"]
  image1?: Maybe<Scalars["String"]>
  image2?: Maybe<Scalars["String"]>
  image3?: Maybe<Scalars["String"]>
  images?: Maybe<Array<Scalars["String"]>>
  isFeatureable: Scalars["Boolean"]
  kind: JobKind
  liked?: Maybe<Scalars["Boolean"]>
  locations: Array<Scalars["String"]>
  members?: Maybe<Array<JobMember>>
  paragraphs?: Maybe<Array<JobParagraph>>
  productImages?: Maybe<Array<Scalars["String"]>>
  productInfo?: Maybe<Scalars["String"]>
  status: JobStatus
  statusesCanChangeTo: Array<JobStatus>
  syncWithCompanyLocations: Scalars["Boolean"]
  targetGrades: Array<JobTargetGrade>
  title: Scalars["String"]
}

export type JobApplication = {
  __typename?: "JobApplication"
  canPostMessage: Scalars["Boolean"]
  company: Company
  createdAt: Scalars["String"]
  id: Scalars["ID"]
  job: Job
  jobSeeker: JobSeeker
  latestStatus?: Maybe<JobApplicationStatus>
  messageThread: MessageThread
  note: Scalars["String"]
  origin: JobApplicationOrigin
}

export enum JobApplicationOrigin {
  /** job_interview */
  JobInterview = "job_interview",
  /** like_from_company */
  LikeFromCompany = "like_from_company",
  /** like_from_seeker */
  LikeFromSeeker = "like_from_seeker",
  /** scout */
  Scout = "scout",
}

export type JobApplicationStatus = {
  __typename?: "JobApplicationStatus"
  endDatetime?: Maybe<Scalars["ISO8601DateTime"]>
  id: Scalars["ID"]
  kind: JobApplicationStatusKind
  startDatetime: Scalars["ISO8601DateTime"]
}

export enum JobApplicationStatusKind {
  /** application_received */
  ApplicationReceived = "application_received",
  /** casual_interview */
  CasualInterview = "casual_interview",
  /** document_screening */
  DocumentScreening = "document_screening",
  /** final_interview */
  FinalInterview = "final_interview",
  /** first_interview */
  FirstInterview = "first_interview",
  /** information_session */
  InformationSession = "information_session",
  /** internship */
  Internship = "internship",
  /** job_offer */
  JobOffer = "job_offer",
  /** job_offer_accepted */
  JobOfferAccepted = "job_offer_accepted",
  /** long_term_internship_accepted */
  LongTermInternshipAccepted = "long_term_internship_accepted",
  /** non_selection_target */
  NonSelectionTarget = "non_selection_target",
  /** not_hired */
  NotHired = "not_hired",
  /** second_interview */
  SecondInterview = "second_interview",
  /** selection_withdrawal */
  SelectionWithdrawal = "selection_withdrawal",
  /** third_interview */
  ThirdInterview = "third_interview",
  /** written_test */
  WrittenTest = "written_test",
}

export type JobApplications = {
  __typename?: "JobApplications"
  data: Array<JobApplication>
  pagination: Pagination
}

export type JobCharacteristic = {
  __typename?: "JobCharacteristic"
  id: Scalars["String"]
  jobCharacteristicLabels: Array<JobCharacteristicLabel>
  name: Scalars["String"]
}

export type JobCharacteristicLabel = {
  __typename?: "JobCharacteristicLabel"
  id: Scalars["ID"]
  jobCharacteristicId: Scalars["ID"]
  name: Scalars["String"]
}

/** Job characteristic label input */
export type JobCharacteristicLabelInput = {
  jobCharacteristicId: Scalars["ID"]
}

export type JobCharacteristics = {
  __typename?: "JobCharacteristics"
  data: Array<JobCharacteristic>
  pagination: Pagination
}

export type JobInterview = {
  __typename?: "JobInterview"
  acceptedAt?: Maybe<Scalars["ISO8601DateTime"]>
  appliedAt?: Maybe<Scalars["ISO8601DateTime"]>
  chatGptThread: ChatGptThread
  chatGptThreadId: Scalars["ID"]
  createdAt: Scalars["ISO8601DateTime"]
  id: Scalars["ID"]
  job: Job
  jobId: Scalars["ID"]
  jobSeeker: JobSeeker
  updatedAt: Scalars["ISO8601DateTime"]
}

export enum JobKind {
  /** full_time */
  FullTime = "full_time",
  /** internship */
  Internship = "internship",
}

export type JobLike = {
  __typename?: "JobLike"
  accepted: Scalars["Boolean"]
  direction: JobLikeDirection
  id: Scalars["String"]
  interestLevel?: Maybe<JobLikeInterestLevel>
  job: Job
  jobSeeker: JobSeeker
  jobSeekerId: Scalars["String"]
  liked: Scalars["Boolean"]
  messageThread?: Maybe<MessageThread>
  sentAt?: Maybe<Scalars["String"]>
}

export enum JobLikeDirection {
  /** company_to_seeker */
  CompanyToSeeker = "company_to_seeker",
  /** seeker_to_company */
  SeekerToCompany = "seeker_to_company",
}

export enum JobLikeInterestLevel {
  /** high */
  High = "high",
  /** low */
  Low = "low",
  /** middle */
  Middle = "middle",
}

export type JobLikes = {
  __typename?: "JobLikes"
  data: Array<JobLike>
  pagination: Pagination
}

export type JobMember = {
  __typename?: "JobMember"
  image: Scalars["String"]
  name: Scalars["String"]
}

/** Job member input */
export type JobMemberInput = {
  /** Image */
  image: Scalars["String"]
  /** Name */
  name: Scalars["String"]
}

export type JobParagraph = {
  __typename?: "JobParagraph"
  body: Scalars["String"]
  index: Scalars["Int"]
  title: Scalars["String"]
}

/** Job paragraph input */
export type JobParagraphInput = {
  body: Scalars["String"]
  title: Scalars["String"]
}

export type JobSeeker = {
  __typename?: "JobSeeker"
  achievements: Array<Achievement>
  address?: Maybe<Scalars["String"]>
  age?: Maybe<Scalars["Int"]>
  bio?: Maybe<Scalars["String"]>
  birthday?: Maybe<Scalars["String"]>
  careerInterests: Array<Scalars["String"]>
  careerInterestsMotivation?: Maybe<Scalars["String"]>
  certifications: Array<Scalars["String"]>
  createdAt: Scalars["ISO8601DateTime"]
  desiredCompanySizes: Array<Scalars["String"]>
  desiredJobTypes: Array<Scalars["String"]>
  desiredWorkLocations: Array<Scalars["String"]>
  educationHistory?: Maybe<EducationHistory>
  email: Scalars["String"]
  emailForProfile?: Maybe<Scalars["String"]>
  firstName: Scalars["String"]
  focusDetail?: Maybe<Scalars["String"]>
  focusSummary?: Maybe<Scalars["String"]>
  fullName: Scalars["String"]
  hasLinkedLineAccount?: Maybe<Scalars["Boolean"]>
  id: Scalars["ID"]
  image?: Maybe<Scalars["String"]>
  introducedByName?: Maybe<Scalars["String"]>
  isFollowingLineAccount?: Maybe<Scalars["Boolean"]>
  isInternal: Scalars["Boolean"]
  isRegisteredRequiredProfile: Scalars["Boolean"]
  lastName: Scalars["String"]
  phone?: Maybe<Scalars["String"]>
  placeOfBirth?: Maybe<Scalars["String"]>
  recruiterNote?: Maybe<RecruiterNote>
  rikelinkUrlForCompany?: Maybe<Scalars["String"]>
  slug: Scalars["String"]
  socialAccounts: Array<SocialAccount>
  status: JobSeekerStatus
  studyDetail: Scalars["String"]
  studyKeywords: Array<Scalars["String"]>
  thingsChangedAfterCovid?: Maybe<Scalars["String"]>
  toeicScore?: Maybe<Scalars["Int"]>
}

export type JobSeekerForRikelink = {
  __typename?: "JobSeekerForRikelink"
  achievements: Array<Achievement>
  address?: Maybe<Scalars["String"]>
  age?: Maybe<Scalars["Int"]>
  bio?: Maybe<Scalars["String"]>
  birthday?: Maybe<Scalars["String"]>
  careerInterests: Array<Scalars["String"]>
  certifications: Array<Scalars["String"]>
  createdAt: Scalars["ISO8601DateTime"]
  desiredCompanySizes: Array<Scalars["String"]>
  desiredJobTypes: Array<Scalars["String"]>
  desiredWorkLocations: Array<Scalars["String"]>
  educationHistory?: Maybe<EducationHistory>
  emailForProfile?: Maybe<Scalars["String"]>
  firstName: Scalars["String"]
  focusDetail?: Maybe<Scalars["String"]>
  focusSummary?: Maybe<Scalars["String"]>
  fullName: Scalars["String"]
  id: Scalars["ID"]
  image?: Maybe<Scalars["String"]>
  lastName: Scalars["String"]
  placeOfBirth?: Maybe<Scalars["String"]>
  slug: Scalars["String"]
  socialAccounts: Array<SocialAccount>
  status: JobSeekerStatus
  studyDetail: Scalars["String"]
  studyKeywords: Array<Scalars["String"]>
  thingsChangedAfterCovid?: Maybe<Scalars["String"]>
  toeicScore?: Maybe<Scalars["Int"]>
}

export type JobSeekerPrivacySetting = {
  __typename?: "JobSeekerPrivacySetting"
  allowEmailPublication: Scalars["Boolean"]
  allowProfileAccessByCompanies: Scalars["Boolean"]
  allowProfilePublication: Scalars["Boolean"]
  id: Scalars["ID"]
  jobSeeker: JobSeeker
}

export type JobSeekerPrivacySettings = {
  __typename?: "JobSeekerPrivacySettings"
  data: Array<JobSeekerPrivacySetting>
  pagination: Pagination
}

export type JobSeekerSearchCondition = {
  __typename?: "JobSeekerSearchCondition"
  id: Scalars["ID"]
  name: Scalars["String"]
  query: JobSeekerSearchConditionQuery
}

export type JobSeekerSearchConditionQuery = {
  __typename?: "JobSeekerSearchConditionQuery"
  filters: Array<JobSeekerSearchConditionQueryFilter>
  sort: SortField
}

export type JobSeekerSearchConditionQueryFilter = {
  __typename?: "JobSeekerSearchConditionQueryFilter"
  field: Scalars["String"]
  value: Array<Scalars["String"]>
}

/** job seeker search condition query input */
export type JobSeekerSearchConditionQueryInput = {
  filters: Array<FilterInput>
  sort: SortInput
}

export enum JobSeekerStatus {
  /** accepted_offer */
  AcceptedOffer = "accepted_offer",
  /** collecting_information */
  CollectingInformation = "collecting_information",
  /** has_offer_and_looking_for_job */
  HasOfferAndLookingForJob = "has_offer_and_looking_for_job",
  /** looking_for_internship */
  LookingForInternship = "looking_for_internship",
  /** looking_for_job */
  LookingForJob = "looking_for_job",
}

export type JobSeekers = {
  __typename?: "JobSeekers"
  data: Array<JobSeeker>
  pagination: Pagination
}

export enum JobStatus {
  /** draft */
  Draft = "draft",
  /** published */
  Published = "published",
  /** submitted */
  Submitted = "submitted",
  /** unpublished */
  Unpublished = "unpublished",
}

export enum JobTargetGrade {
  /** all */
  All = "all",
  /** graduation_2023 */
  Graduation_2023 = "graduation_2023",
  /** graduation_2024 */
  Graduation_2024 = "graduation_2024",
  /** graduation_2025 */
  Graduation_2025 = "graduation_2025",
  /** graduation_2026 */
  Graduation_2026 = "graduation_2026",
  /** graduation_2027 */
  Graduation_2027 = "graduation_2027",
  /** graduation_2028 */
  Graduation_2028 = "graduation_2028",
}

export type Jobs = {
  __typename?: "Jobs"
  data: Array<Job>
  pagination: Pagination
}

export type MailNotification = {
  __typename?: "MailNotification"
  body: Scalars["String"]
  createdAt: Scalars["ISO8601DateTime"]
  id: Scalars["ID"]
  sendAt: Scalars["ISO8601DateTime"]
  subject: Scalars["String"]
  updatedAt: Scalars["ISO8601DateTime"]
}

export type MailNotificationInput = {
  body?: InputMaybe<Scalars["String"]>
  sendAt?: InputMaybe<Scalars["ISO8601DateTime"]>
  subject?: InputMaybe<Scalars["String"]>
}

export type MailNotifications = {
  __typename?: "MailNotifications"
  data: Array<MailNotification>
  pagination: Pagination
}

export type Member = {
  __typename?: "Member"
  avatar?: Maybe<Scalars["String"]>
  bio: Scalars["String"]
  company: Company
  id: Scalars["ID"]
  jobTitle: Scalars["String"]
  messageToStudents?: Maybe<Scalars["String"]>
  name: Scalars["String"]
}

export type Message = {
  __typename?: "Message"
  companyStaff?: Maybe<CompanyStaff>
  createdAt: Scalars["String"]
  id: Scalars["ID"]
  jobSeeker?: Maybe<JobSeeker>
  kind: MessageKind
  readByJobSeeker: Scalars["Boolean"]
  text: Scalars["String"]
}

export enum MessageKind {
  /** from_company */
  FromCompany = "from_company",
  /** from_seeker */
  FromSeeker = "from_seeker",
  /** notification */
  Notification = "notification",
}

export type MessageTemplate = {
  __typename?: "MessageTemplate"
  id: Scalars["ID"]
  kind: MessageTemplateKind
  name: Scalars["String"]
  text: Scalars["String"]
  updatedAt: Scalars["String"]
}

export enum MessageTemplateKind {
  /** like */
  Like = "like",
  /** remind */
  Remind = "remind",
  /** scout */
  Scout = "scout",
  /** selection */
  Selection = "selection",
}

export type MessageTemplates = {
  __typename?: "MessageTemplates"
  data: Array<MessageTemplate>
  pagination: Pagination
}

export type MessageThread = {
  __typename?: "MessageThread"
  canAcceptScout: Scalars["Boolean"]
  canPostMessage: Scalars["Boolean"]
  company: Company
  companyLastMessageSentAt?: Maybe<Scalars["ISO8601DateTime"]>
  createdAt: Scalars["String"]
  hasLastMessageRead?: Maybe<Scalars["Boolean"]>
  hasUnreadByJobSeeker: Scalars["Boolean"]
  id: Scalars["ID"]
  isLike: Scalars["Boolean"]
  job: Job
  jobInterview?: Maybe<JobInterview>
  jobSeeker: JobSeeker
  lastMessageSentByCompany?: Maybe<Scalars["Boolean"]>
  latestMessage?: Maybe<Message>
  messages: Array<Message>
  origin: MessageThreadOrigin
  scoutMailOpenedAt?: Maybe<Scalars["String"]>
  scoutSentAt?: Maybe<Scalars["String"]>
  updatedAt: Scalars["ISO8601DateTime"]
}

export enum MessageThreadOrigin {
  /** job_interview */
  JobInterview = "job_interview",
  /** like_from_company */
  LikeFromCompany = "like_from_company",
  /** like_from_seeker */
  LikeFromSeeker = "like_from_seeker",
  /** scout */
  Scout = "scout",
}

export type MessageThreads = {
  __typename?: "MessageThreads"
  data: Array<MessageThread>
  pagination: Pagination
}

export type Mutation = {
  __typename?: "Mutation"
  acceptJobInterview?: Maybe<BooleanResult>
  acceptScout?: Maybe<MessageThread>
  adminUpdateCompany: Company
  applyJobInterview?: Maybe<BooleanResult>
  associateSocialAccount?: Maybe<SocialAccount>
  createAchievement?: Maybe<Achievement>
  createAiInterviewHistory?: Maybe<BooleanResult>
  createChatGptMessage: ChatGptThread
  createChatGptThread?: Maybe<ChatGptThread>
  createCompany: Company
  createCompanyStaff: CompanyStaff
  createFeaturedJob: FeaturedJob
  createJob: Job
  createJobApplicationStatus: JobApplicationStatus
  createJobCharacteristic: JobCharacteristic
  createJobInterview?: Maybe<JobInterview>
  createJobLike?: Maybe<JobLike>
  createJobSeekerSearchCondition: JobSeekerSearchCondition
  createMailNotification: MailNotification
  createMember: Member
  createMessageTemplate: MessageTemplate
  createProduct: Product
  createRecruiterNote: RecruiterNote
  createReferralComment: ReferralComment
  createUserCampaignView: UserCampaignView
  deleteAchievement: BooleanResult
  deleteAiInterviewHistory?: Maybe<BooleanResult>
  deleteFeaturedJob: BooleanResult
  deleteJobCharacteristic: BooleanResult
  deleteJobSeeker: BooleanResult
  deleteJobSeekerSearchCondition: BooleanResult
  deleteMember: BooleanResult
  deleteMessageTemplate: BooleanResult
  deleteProduct: BooleanResult
  disassociateSocialAccount: BooleanResult
  /** @deprecated Use createJobLike instead */
  likeJob?: Maybe<JobLike>
  likeJobSeeker?: Maybe<JobLike>
  likeJobSeekers?: Maybe<Array<JobLike>>
  markJobLikesAsViewedByJobSeeker: BooleanResult
  postMessage?: Maybe<MessageThread>
  sendScout?: Maybe<MessageThread>
  sendScouts?: Maybe<Array<MessageThread>>
  storeChatGptMessages: ChatGptThread
  updateAchievement: Achievement
  updateCompany: Company
  updateCompanyPlan: Company
  updateFeatureTopic: FeatureTopic
  updateJob?: Maybe<Job>
  updateJobApplication: JobApplication
  updateJobCharacteristic: JobCharacteristic
  updateJobSeeker?: Maybe<JobSeeker>
  updateJobSeekerPrivacySetting: JobSeekerPrivacySetting
  updateJobStatus?: Maybe<Job>
  updateMailNotification: MailNotification
  updateMember: Member
  updateMessageTemplate: MessageTemplate
  updateProduct: Product
  updateRecruiterNote: RecruiterNote
}

export type MutationAcceptJobInterviewArgs = {
  input: AcceptJobInterviewInput
}

export type MutationAcceptScoutArgs = {
  threadId: Scalars["String"]
}

export type MutationAdminUpdateCompanyArgs = {
  input: UpdateCompanyInput
}

export type MutationApplyJobInterviewArgs = {
  input: ApplyJobInterviewInput
}

export type MutationAssociateSocialAccountArgs = {
  input: AssociateSocialAccountInput
}

export type MutationCreateAchievementArgs = {
  input: AchievementInput
}

export type MutationCreateAiInterviewHistoryArgs = {
  input: CreateAiInterviewHistoryInput
}

export type MutationCreateChatGptMessageArgs = {
  input: CreateChatGptMessageInput
}

export type MutationCreateChatGptThreadArgs = {
  input: CreateChatGptThreadInput
}

export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput
}

export type MutationCreateCompanyStaffArgs = {
  input: CreateCompanyStaffInput
}

export type MutationCreateFeaturedJobArgs = {
  input: CreateFeaturedJobInput
}

export type MutationCreateJobArgs = {
  input: CreateJobInput
}

export type MutationCreateJobApplicationStatusArgs = {
  input: CreateJobApplicationStatusInput
}

export type MutationCreateJobCharacteristicArgs = {
  input: CreateJobCharacteristicInput
}

export type MutationCreateJobInterviewArgs = {
  input: CreateJobInterviewInput
}

export type MutationCreateJobLikeArgs = {
  input: CreateJobLikeInput
}

export type MutationCreateJobSeekerSearchConditionArgs = {
  input: CreateJobSeekerSearchConditionInput
}

export type MutationCreateMailNotificationArgs = {
  input: MailNotificationInput
}

export type MutationCreateMemberArgs = {
  input: CreateMemberInput
}

export type MutationCreateMessageTemplateArgs = {
  input: CreateMessageTemplateInput
}

export type MutationCreateProductArgs = {
  input: CreateProductInput
}

export type MutationCreateRecruiterNoteArgs = {
  input: CreateRecruiterNoteInput
}

export type MutationCreateReferralCommentArgs = {
  input: CreateReferralCommentInput
}

export type MutationCreateUserCampaignViewArgs = {
  input: CreateUserCampaignViewInput
}

export type MutationDeleteAchievementArgs = {
  id: Scalars["String"]
}

export type MutationDeleteAiInterviewHistoryArgs = {
  input: DeleteAiInterviewHistoryInput
}

export type MutationDeleteFeaturedJobArgs = {
  id: Scalars["ID"]
}

export type MutationDeleteJobCharacteristicArgs = {
  id: Scalars["ID"]
}

export type MutationDeleteJobSeekerArgs = {
  id: Scalars["ID"]
}

export type MutationDeleteJobSeekerSearchConditionArgs = {
  id: Scalars["ID"]
}

export type MutationDeleteMemberArgs = {
  id: Scalars["ID"]
}

export type MutationDeleteMessageTemplateArgs = {
  id: Scalars["ID"]
}

export type MutationDeleteProductArgs = {
  id: Scalars["ID"]
}

export type MutationDisassociateSocialAccountArgs = {
  provider: SocialAccountProvider
}

export type MutationLikeJobArgs = {
  id: Scalars["String"]
}

export type MutationLikeJobSeekerArgs = {
  jobId: Scalars["String"]
  jobSeekerId: Scalars["String"]
}

export type MutationLikeJobSeekersArgs = {
  jobId: Scalars["String"]
  jobSeekerIds: Array<Scalars["String"]>
}

export type MutationMarkJobLikesAsViewedByJobSeekerArgs = {
  ids: Array<Scalars["ID"]>
}

export type MutationPostMessageArgs = {
  text: Scalars["String"]
  threadId: Scalars["String"]
}

export type MutationSendScoutArgs = {
  jobId: Scalars["String"]
  jobSeekerId: Scalars["String"]
  remindText?: InputMaybe<Scalars["String"]>
  text: Scalars["String"]
}

export type MutationSendScoutsArgs = {
  jobId: Scalars["String"]
  jobSeekerIds: Array<Scalars["String"]>
  remindText?: InputMaybe<Scalars["String"]>
  text: Scalars["String"]
}

export type MutationStoreChatGptMessagesArgs = {
  input: StoreChatGptMessagesInput
}

export type MutationUpdateAchievementArgs = {
  id: Scalars["String"]
  input: AchievementInput
}

export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput
}

export type MutationUpdateCompanyPlanArgs = {
  input: UpdateCompanyPlanInput
}

export type MutationUpdateFeatureTopicArgs = {
  input: UpdateFeatureTopicInput
}

export type MutationUpdateJobArgs = {
  input: UpdateJobInput
}

export type MutationUpdateJobApplicationArgs = {
  input: UpdateJobApplicationInput
}

export type MutationUpdateJobCharacteristicArgs = {
  input: UpdateJobCharacteristicInput
}

export type MutationUpdateJobSeekerArgs = {
  input: UpdateJobSeekerInput
}

export type MutationUpdateJobSeekerPrivacySettingArgs = {
  input: UpdateJobSeekerPrivacySettingInput
}

export type MutationUpdateJobStatusArgs = {
  id: Scalars["ID"]
  status: JobStatus
}

export type MutationUpdateMailNotificationArgs = {
  id: Scalars["ID"]
  input: MailNotificationInput
}

export type MutationUpdateMemberArgs = {
  input: UpdateMemberInput
}

export type MutationUpdateMessageTemplateArgs = {
  input: UpdateMessageTemplateInput
}

export type MutationUpdateProductArgs = {
  input: UpdateProductInput
}

export type MutationUpdateRecruiterNoteArgs = {
  input: UpdateRecruiterNoteInput
}

export type PaginatedJobInterviews = {
  __typename?: "PaginatedJobInterviews"
  data: Array<JobInterview>
  pagination: Pagination
}

export type Pagination = {
  __typename?: "Pagination"
  page: Scalars["Int"]
  perPage: Scalars["Int"]
  total: Scalars["Int"]
}

/** Pagination input */
export type PaginationInput = {
  /** Page number */
  page?: InputMaybe<Scalars["Int"]>
  /** Items per page */
  perPage?: InputMaybe<Scalars["Int"]>
}

export type Prefecture = {
  __typename?: "Prefecture"
  id: Scalars["ID"]
  value: Scalars["String"]
}

export type Product = {
  __typename?: "Product"
  company: Company
  description: Scalars["String"]
  id: Scalars["ID"]
  image?: Maybe<Scalars["String"]>
  name: Scalars["String"]
}

export type Query = {
  __typename?: "Query"
  aiInterviewAvailability: AiInterviewAvailability
  aiInterviewHistories: Array<AiInterviewHistory>
  aiInterviewHistory: AiInterviewHistory
  allFeatureTopics: Array<FeatureTopic>
  applications: JobApplications
  askChatGpt?: Maybe<ChatGptResponse>
  chatGptMessages: Array<ChatGptMessage>
  chatGptThread: ChatGptThread
  chatGptThreads: Array<ChatGptThread>
  companies: Companies
  companiesById: Array<Company>
  company?: Maybe<Company>
  educationalInstitution?: Maybe<EducationalInstitution>
  educationalInstitutions: Array<EducationalInstitution>
  featureTopic?: Maybe<FeatureTopic>
  featureTopicBySlug?: Maybe<FeatureTopic>
  featureTopics: FeatureTopics
  featuredJobs: FeaturedJobs
  featuredJobsByTopicId: Array<FeaturedJob>
  interviewTemplates: Array<InterviewTemplate>
  job?: Maybe<Job>
  jobCharacteristics: JobCharacteristics
  jobCharacteristicsById: Array<JobCharacteristic>
  jobInterviews: PaginatedJobInterviews
  jobInterviewsAll: Array<JobInterview>
  jobLikes: JobLikes
  jobSeeker?: Maybe<JobSeeker>
  jobSeekerBySlug: JobSeekerForRikelink
  jobSeekerMe?: Maybe<JobSeeker>
  jobSeekerPrivacySetting?: Maybe<JobSeekerPrivacySetting>
  jobSeekerPrivacySettings: JobSeekerPrivacySettings
  jobSeekerSearchConditions: Array<JobSeekerSearchCondition>
  jobSeekers: JobSeekers
  jobSeekersById: Array<JobSeeker>
  jobs: Jobs
  jobsByCompany?: Maybe<Jobs>
  jobsById: Array<Job>
  latestCampaign?: Maybe<Campaign>
  latestCampaigns: Array<Campaign>
  mailNotification?: Maybe<MailNotification>
  mailNotifications: MailNotifications
  member?: Maybe<Member>
  members: Array<Member>
  messageTemplate?: Maybe<MessageTemplate>
  messageTemplates: MessageTemplates
  messageThread?: Maybe<MessageThread>
  messageThreads: MessageThreads
  myCompany?: Maybe<Company>
  prefectures: Array<Prefecture>
  product?: Maybe<Product>
  products: Array<Product>
  referralComments: Array<ReferralComment>
  unreadItemsCount: UnreadItemsCount
}

export type QueryAiInterviewAvailabilityArgs = {
  jobId?: InputMaybe<Scalars["ID"]>
}

export type QueryAiInterviewHistoriesArgs = {
  jobSeekerId?: InputMaybe<Scalars["ID"]>
}

export type QueryAiInterviewHistoryArgs = {
  chatGptThreadId?: InputMaybe<Scalars["ID"]>
}

export type QueryApplicationsArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<SortInput>
}

export type QueryAskChatGptArgs = {
  question: Scalars["String"]
  systemContent?: InputMaybe<Scalars["String"]>
}

export type QueryChatGptMessagesArgs = {
  threadId: Scalars["ID"]
}

export type QueryChatGptThreadArgs = {
  id?: InputMaybe<Scalars["ID"]>
  messageThreadId?: InputMaybe<Scalars["ID"]>
}

export type QueryCompaniesArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<SortInput>
}

export type QueryCompaniesByIdArgs = {
  ids: Array<Scalars["ID"]>
}

export type QueryCompanyArgs = {
  id: Scalars["ID"]
}

export type QueryEducationalInstitutionArgs = {
  name: Scalars["String"]
}

export type QueryFeatureTopicArgs = {
  id: Scalars["ID"]
}

export type QueryFeatureTopicBySlugArgs = {
  slug: Scalars["String"]
}

export type QueryFeatureTopicsArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<SortInput>
}

export type QueryFeaturedJobsArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<SortInput>
}

export type QueryFeaturedJobsByTopicIdArgs = {
  featureTopicId: Scalars["ID"]
}

export type QueryJobArgs = {
  id: Scalars["ID"]
}

export type QueryJobCharacteristicsArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<SortInput>
}

export type QueryJobCharacteristicsByIdArgs = {
  ids: Array<Scalars["ID"]>
}

export type QueryJobInterviewsArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<SortInput>
}

export type QueryJobLikesArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<SortInput>
}

export type QueryJobSeekerArgs = {
  id: Scalars["ID"]
}

export type QueryJobSeekerBySlugArgs = {
  slug: Scalars["String"]
}

export type QueryJobSeekerPrivacySettingsArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<SortInput>
}

export type QueryJobSeekersArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<SortInput>
}

export type QueryJobSeekersByIdArgs = {
  ids: Array<Scalars["ID"]>
}

export type QueryJobsArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<SortInput>
}

export type QueryJobsByCompanyArgs = {
  companyId: Scalars["ID"]
  pagination?: InputMaybe<PaginationInput>
}

export type QueryJobsByIdArgs = {
  ids: Array<Scalars["ID"]>
}

export type QueryMailNotificationArgs = {
  id: Scalars["ID"]
}

export type QueryMailNotificationsArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<SortInput>
}

export type QueryMemberArgs = {
  id: Scalars["ID"]
}

export type QueryMessageTemplateArgs = {
  id: Scalars["ID"]
}

export type QueryMessageTemplatesArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<SortInput>
}

export type QueryMessageThreadArgs = {
  id: Scalars["ID"]
}

export type QueryMessageThreadsArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  pagination?: InputMaybe<PaginationInput>
}

export type QueryProductArgs = {
  id: Scalars["ID"]
}

export type QueryReferralCommentsArgs = {
  slug: Scalars["String"]
}

export type RecruiterNote = {
  __typename?: "RecruiterNote"
  comment: Scalars["String"]
  id: Scalars["ID"]
  isRead: Scalars["Boolean"]
}

export type ReferralComment = {
  __typename?: "ReferralComment"
  avatar?: Maybe<Scalars["String"]>
  comment: Scalars["String"]
  createdAt: Scalars["ISO8601DateTime"]
  id: Scalars["ID"]
  name: Scalars["String"]
  organization: Scalars["String"]
  relationship: Scalars["String"]
}

export type SocialAccount = {
  __typename?: "SocialAccount"
  profileUrl: Scalars["String"]
  provider: SocialAccountProvider
}

export enum SocialAccountProvider {
  /** github */
  Github = "github",
  /** line */
  Line = "line",
  /** note */
  Note = "note",
  /** qiita */
  Qiita = "qiita",
  /** twitter */
  Twitter = "twitter",
}

/** Sort field */
export type SortField = {
  __typename?: "SortField"
  field: Scalars["String"]
  /** Sort order */
  order: SortOrder
}

/** Sort input */
export type SortInput = {
  /** Field name */
  field: Scalars["String"]
  /** Sort order */
  order: SortOrder
}

export enum SortOrder {
  /** ascending */
  Asc = "ASC",
  /** descending */
  Desc = "DESC",
}

/** Store chat gpt messages input */
export type StoreChatGptMessagesInput = {
  inputSource?: InputMaybe<ChatGptMessageInputSource>
  messages: Array<ChatGptMessageInput>
  threadId?: InputMaybe<Scalars["ID"]>
}

export type UnreadItemsCount = {
  __typename?: "UnreadItemsCount"
  jobLikes: Scalars["Int"]
  messages: Scalars["Int"]
  sentScouts: Scalars["Int"]
}

/** Update company input */
export type UpdateCompanyInput = {
  businessCategories?: InputMaybe<Array<Scalars["String"]>>
  businessDescription?: InputMaybe<Scalars["String"]>
  corporatePhilosophy?: InputMaybe<Scalars["String"]>
  coverImage?: InputMaybe<Scalars["String"]>
  employeesNumber?: InputMaybe<Scalars["Int"]>
  foundedOn?: InputMaybe<Scalars["String"]>
  headquartersAddress?: InputMaybe<Scalars["String"]>
  homepage?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["ID"]>
  industry?: InputMaybe<Scalars["String"]>
  locations?: InputMaybe<Array<Scalars["String"]>>
  logo?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  offices?: InputMaybe<Scalars["String"]>
  representative?: InputMaybe<Scalars["String"]>
  workStyleAttraction?: InputMaybe<Scalars["String"]>
}

/** Update company plan input */
export type UpdateCompanyPlanInput = {
  expiresOn: Scalars["ISO8601Date"]
  id: Scalars["ID"]
  likeCount: Scalars["Int"]
  scoutCount: Scalars["Int"]
}

/** Update feature topic input */
export type UpdateFeatureTopicInput = {
  description?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
  slug?: InputMaybe<Scalars["String"]>
  title?: InputMaybe<Scalars["String"]>
}

/** Update job application input */
export type UpdateJobApplicationInput = {
  id: Scalars["ID"]
  note: Scalars["String"]
}

/** Update job characteristic input */
export type UpdateJobCharacteristicInput = {
  id: Scalars["ID"]
  name?: InputMaybe<Scalars["String"]>
}

/** Update job input */
export type UpdateJobInput = {
  coverImage?: InputMaybe<Scalars["String"]>
  description?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
  idealCandidate?: InputMaybe<Scalars["String"]>
  image1?: InputMaybe<Scalars["String"]>
  image2?: InputMaybe<Scalars["String"]>
  image3?: InputMaybe<Scalars["String"]>
  images?: InputMaybe<Array<Scalars["String"]>>
  isFeatureable?: InputMaybe<Scalars["Boolean"]>
  jobCharacteristicLabels?: InputMaybe<Array<JobCharacteristicLabelInput>>
  kind?: InputMaybe<JobKind>
  locations?: InputMaybe<Array<Scalars["String"]>>
  members?: InputMaybe<Array<JobMemberInput>>
  paragraphs?: InputMaybe<Array<JobParagraphInput>>
  productImages?: InputMaybe<Array<Scalars["String"]>>
  productInfo?: InputMaybe<Scalars["String"]>
  status?: InputMaybe<JobStatus>
  syncWithCompanyLocations?: InputMaybe<Scalars["Boolean"]>
  targetGrades?: InputMaybe<Array<AvailableJobTargetGrade>>
  title?: InputMaybe<Scalars["String"]>
}

/** Update job seeker input */
export type UpdateJobSeekerInput = {
  address?: InputMaybe<Scalars["String"]>
  bio?: InputMaybe<Scalars["String"]>
  birthday?: InputMaybe<Scalars["String"]>
  careerInterests?: InputMaybe<Array<Scalars["String"]>>
  careerInterestsMotivation?: InputMaybe<Scalars["String"]>
  certifications?: InputMaybe<Array<Scalars["String"]>>
  desiredCompanySizes?: InputMaybe<Array<Scalars["String"]>>
  desiredJobTypes?: InputMaybe<Array<Scalars["String"]>>
  desiredWorkLocations?: InputMaybe<Array<Scalars["String"]>>
  educationHistory?: InputMaybe<EducationHistoryInput>
  email?: InputMaybe<Scalars["String"]>
  firstName?: InputMaybe<Scalars["String"]>
  focusDetail?: InputMaybe<Scalars["String"]>
  focusSummary?: InputMaybe<Scalars["String"]>
  image?: InputMaybe<Scalars["String"]>
  introducedById?: InputMaybe<Scalars["ID"]>
  lastName?: InputMaybe<Scalars["String"]>
  phone?: InputMaybe<Scalars["String"]>
  placeOfBirth?: InputMaybe<Scalars["String"]>
  slug?: InputMaybe<Scalars["String"]>
  status?: InputMaybe<JobSeekerStatus>
  studyDetail?: InputMaybe<Scalars["String"]>
  studyKeywords?: InputMaybe<Array<Scalars["String"]>>
  studyKeywordsString?: InputMaybe<Scalars["String"]>
  thingsChangedAfterCovid?: InputMaybe<Scalars["String"]>
  toeicScore?: InputMaybe<Scalars["Int"]>
}

export type UpdateJobSeekerPrivacySettingInput = {
  allowEmailPublication?: InputMaybe<Scalars["Boolean"]>
  allowProfileAccessByCompanies?: InputMaybe<Scalars["Boolean"]>
  allowProfilePublication?: InputMaybe<Scalars["Boolean"]>
}

/** Update member input */
export type UpdateMemberInput = {
  avatar?: InputMaybe<Scalars["String"]>
  bio?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
  jobTitle?: InputMaybe<Scalars["String"]>
  messageToStudents?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
}

/** Update message template input */
export type UpdateMessageTemplateInput = {
  id: Scalars["ID"]
  kind?: InputMaybe<MessageTemplateKind>
  name?: InputMaybe<Scalars["String"]>
  text?: InputMaybe<Scalars["String"]>
}

/** Update product input */
export type UpdateProductInput = {
  description?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
  image?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
}

/** Update recruiter note */
export type UpdateRecruiterNoteInput = {
  comment: Scalars["String"]
  id: Scalars["ID"]
}

export type UserCampaignView = {
  __typename?: "UserCampaignView"
  id: Scalars["ID"]
}
