/* eslint-disable no-unused-vars, no-shadow, prefer-arrow/prefer-arrow-functions */
import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"

import type {
  Exact,
  InputMaybe,
  AchievementInput as JobSeekerAchievementInput,
  ApplyJobInterviewInput as JobSeekerApplyJobInterviewInput,
  AssociateSocialAccountInput as JobSeekerAssociateSocialAccountInput,
  ChatGptMessageInputSource as JobSeekerChatGptMessageInputSource,
  ChatGptMessageRole as JobSeekerChatGptMessageRole,
  ChatGptThreadKind as JobSeekerChatGptThreadKind,
  CreateAiInterviewHistoryInput as JobSeekerCreateAiInterviewHistoryInput,
  CreateChatGptMessageInput as JobSeekerCreateChatGptMessageInput,
  CreateChatGptThreadInput as JobSeekerCreateChatGptThreadInput,
  CreateJobInterviewInput as JobSeekerCreateJobInterviewInput,
  CreateJobLikeInput as JobSeekerCreateJobLikeInput,
  CreateReferralCommentInput as JobSeekerCreateReferralCommentInput,
  CreateUserCampaignViewInput as JobSeekerCreateUserCampaignViewInput,
  DeleteAiInterviewHistoryInput as JobSeekerDeleteAiInterviewHistoryInput,
  EducationHistoryGrade as JobSeekerEducationHistoryGrade,
  FilterInput as JobSeekerFilterInput,
  JobKind as JobSeekerJobKind,
  JobLikeDirection as JobSeekerJobLikeDirection,
  JobTargetGrade as JobSeekerJobTargetGrade,
  MessageKind as JobSeekerMessageKind,
  MessageThreadOrigin as JobSeekerMessageThreadOrigin,
  PaginationInput as JobSeekerPaginationInput,
  SocialAccountProvider as JobSeekerSocialAccountProvider,
  SortInput as JobSeekerSortInput,
  JobSeekerStatus,
  StoreChatGptMessagesInput as JobSeekerStoreChatGptMessagesInput,
  UpdateJobSeekerInput as JobSeekerUpdateJobSeekerInput,
  UpdateJobSeekerPrivacySettingInput as JobSeekerUpdateJobSeekerPrivacySettingInput,
  Scalars,
} from "rikeimatch-graphql"

const defaultOptions = {} as const
export type JobSeekerCreateAiInterviewHistoryMutationVariables = Exact<{
  input: JobSeekerCreateAiInterviewHistoryInput
}>

export type JobSeekerCreateAiInterviewHistoryMutation = {
  createAiInterviewHistory?: { success: boolean } | null
}

export type JobSeekerAiInterviewHistoriesQueryVariables = Exact<{ [key: string]: never }>

export type JobSeekerAiInterviewHistoriesQuery = {
  aiInterviewHistories: Array<{
    id: string
    createdAt: string
    chatGptThread: { id: string; kind: JobSeekerChatGptThreadKind; createdAt: string }
  }>
}

export type JobSeekerAiInterviewHistoryQueryVariables = Exact<{
  chatGptThreadId: Scalars["ID"]
}>

export type JobSeekerAiInterviewHistoryQuery = {
  aiInterviewHistory: {
    id: string
    createdAt: string
    chatGptThread: { id: string; kind: JobSeekerChatGptThreadKind; createdAt: string }
  }
}

export type JobSeekerDeleteAiInterviewHistoryMutationVariables = Exact<{
  input: JobSeekerDeleteAiInterviewHistoryInput
}>

export type JobSeekerDeleteAiInterviewHistoryMutation = {
  deleteAiInterviewHistory?: { success: boolean } | null
}

export type JobSeekerLatestCampaignQueryVariables = Exact<{ [key: string]: never }>

export type JobSeekerLatestCampaignQuery = {
  latestCampaign?: {
    id: string
    name: string
    code: string
    startsAt: string
    endsAt: string
    viewed: boolean
  } | null
}

export type JobSeekerLatestCampaignsQueryVariables = Exact<{ [key: string]: never }>

export type JobSeekerLatestCampaignsQuery = {
  latestCampaigns: Array<{
    id: string
    name: string
    code: string
    startsAt: string
    endsAt: string
    viewed: boolean
  }>
}

export type JobSeekerCreateUserCampaignViewMutationVariables = Exact<{
  input: JobSeekerCreateUserCampaignViewInput
}>

export type JobSeekerCreateUserCampaignViewMutation = { createUserCampaignView: { id: string } }

export type JobSeekerAskChatGptQueryVariables = Exact<{
  question: Scalars["String"]
  systemContent?: InputMaybe<Scalars["String"]>
}>

export type JobSeekerAskChatGptQuery = { askChatGpt?: { answer: string } | null }

export type JobSeekerCreateChatGptMessageMutationVariables = Exact<{
  input: JobSeekerCreateChatGptMessageInput
}>

export type JobSeekerCreateChatGptMessageMutation = {
  createChatGptMessage: {
    id: string
    kind: JobSeekerChatGptThreadKind
    messages: Array<{
      id: string
      content: string
      role: JobSeekerChatGptMessageRole
      inputSource: JobSeekerChatGptMessageInputSource
    }>
  }
}

export type JobSeekerStoreChatGptMessagesMutationVariables = Exact<{
  input: JobSeekerStoreChatGptMessagesInput
}>

export type JobSeekerStoreChatGptMessagesMutation = {
  storeChatGptMessages: {
    id: string
    kind: JobSeekerChatGptThreadKind
    messages: Array<{
      id: string
      content: string
      role: JobSeekerChatGptMessageRole
      inputSource: JobSeekerChatGptMessageInputSource
    }>
  }
}

export type JobSeekerCreateChatGptThreadMutationVariables = Exact<{
  input: JobSeekerCreateChatGptThreadInput
}>

export type JobSeekerCreateChatGptThreadMutation = {
  createChatGptThread?: {
    id: string
    kind: JobSeekerChatGptThreadKind
    messages: Array<{
      id: string
      content: string
      role: JobSeekerChatGptMessageRole
      inputSource: JobSeekerChatGptMessageInputSource
    }>
  } | null
}

export type JobSeekerChatGptThreadQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type JobSeekerChatGptThreadQuery = {
  chatGptThread: {
    id: string
    kind: JobSeekerChatGptThreadKind
    messages: Array<{
      id: string
      content: string
      role: JobSeekerChatGptMessageRole
      inputSource: JobSeekerChatGptMessageInputSource
    }>
  }
}

export type JobSeekerAiInterviewAvailabilityQueryVariables = Exact<{
  jobId?: InputMaybe<Scalars["ID"]>
}>

export type JobSeekerAiInterviewAvailabilityQuery = {
  aiInterviewAvailability: {
    availableInterviewsCount: number
    availableJobInterviewsCount: number
    availableForJob: boolean
    incompleteProfileFieldErrors: Array<string>
  }
}

export type JobSeekerChatGptMessagesQueryVariables = Exact<{
  threadId: Scalars["ID"]
}>

export type JobSeekerChatGptMessagesQuery = {
  chatGptMessages: Array<{
    id: string
    role: JobSeekerChatGptMessageRole
    content: string
    inputSource: JobSeekerChatGptMessageInputSource
    createdAt: string
  }>
}

export type JobSeekerChatGptThreadsQueryVariables = Exact<{ [key: string]: never }>

export type JobSeekerChatGptThreadsQuery = {
  chatGptThreads: Array<{ id: string; kind: JobSeekerChatGptThreadKind; createdAt: string }>
}

export type JobSeekerEducationalInstitutionsQueryVariables = Exact<{ [key: string]: never }>

export type JobSeekerEducationalInstitutionsQuery = {
  educationalInstitutions: Array<{ id: string; name: string }>
}

export type JobSeekerEducationalInstitutionQueryVariables = Exact<{
  name: Scalars["String"]
}>

export type JobSeekerEducationalInstitutionQuery = {
  educationalInstitution?: { id: string; name: string } | null
}

export type JobSeekerShowFeatureTopicQueryVariables = Exact<{
  slug: Scalars["String"]
}>

export type JobSeekerShowFeatureTopicQuery = {
  featureTopicBySlug?: {
    id: string
    title: string
    description: string
    jobs: Array<{
      id: string
      title: string
      descriptionText: string
      coverImage?: string | null
      liked?: boolean | null
      accepted?: boolean | null
      company: { name: string }
      characteristicLabels: Array<{ id: string; name: string }>
    }>
  } | null
}

export type JobSeekerListFeatureTopicsQueryVariables = Exact<{ [key: string]: never }>

export type JobSeekerListFeatureTopicsQuery = {
  featureTopics: { data: Array<{ id: string; title: string; description: string; slug: string }> }
}

export type JobSeekerListFeaturedJobsQueryVariables = Exact<{
  pagination?: InputMaybe<JobSeekerPaginationInput>
  filters?: InputMaybe<Array<JobSeekerFilterInput> | JobSeekerFilterInput>
  sort?: InputMaybe<JobSeekerSortInput>
}>

export type JobSeekerListFeaturedJobsQuery = {
  featuredJobs: {
    data: Array<{
      id: string
      job: {
        id: string
        title: string
        descriptionText: string
        coverImage?: string | null
        liked?: boolean | null
        accepted?: boolean | null
        kind: JobSeekerJobKind
        targetGrades: Array<JobSeekerJobTargetGrade>
        locations: Array<string>
        company: { name: string }
        characteristicLabels: Array<{ id: string; name: string }>
      }
    }>
    pagination: { total: number }
  }
}

export type JobSeekerCommonCompanyFragment = {
  id: string
  name: string
  foundedOn?: string | null
  employeesNumber?: number | null
  headquartersAddress?: string | null
  prefecture?: string | null
  offices?: string | null
  logo?: string | null
  coverImage?: string | null
  homepage?: string | null
  industry?: string | null
  businessDescription?: string | null
  businessCategories: Array<string>
  corporatePhilosophy?: string | null
  locations: Array<string>
  workStyleAttraction?: string | null
  members: Array<{
    id: string
    name: string
    jobTitle: string
    bio: string
    avatar?: string | null
  }>
  products: Array<{ id: string; name: string; description: string; image?: string | null }>
}

export type JobSeekerCommonJobFragment = {
  id: string
  title: string
  description: string
  coverImage?: string | null
  images?: Array<string> | null
  idealCandidate: string
  kind: JobSeekerJobKind
  locations: Array<string>
  targetGrades: Array<JobSeekerJobTargetGrade>
  productInfo?: string | null
  productImages?: Array<string> | null
  company: {
    id: string
    name: string
    foundedOn?: string | null
    employeesNumber?: number | null
    headquartersAddress?: string | null
    prefecture?: string | null
    offices?: string | null
    logo?: string | null
    coverImage?: string | null
    homepage?: string | null
    industry?: string | null
    businessDescription?: string | null
    businessCategories: Array<string>
    corporatePhilosophy?: string | null
    locations: Array<string>
    workStyleAttraction?: string | null
    members: Array<{
      id: string
      name: string
      jobTitle: string
      bio: string
      avatar?: string | null
    }>
    products: Array<{ id: string; name: string; description: string; image?: string | null }>
  }
  members?: Array<{ name: string; image: string }> | null
  paragraphs?: Array<{ index: number; title: string; body: string }> | null
}

export type JobSeekerShowJobQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type JobSeekerShowJobQuery = {
  job?: {
    liked?: boolean | null
    accepted?: boolean | null
    id: string
    title: string
    description: string
    coverImage?: string | null
    images?: Array<string> | null
    idealCandidate: string
    kind: JobSeekerJobKind
    locations: Array<string>
    targetGrades: Array<JobSeekerJobTargetGrade>
    productInfo?: string | null
    productImages?: Array<string> | null
    characteristicLabels: Array<{ id: string; name: string }>
    company: {
      id: string
      name: string
      foundedOn?: string | null
      employeesNumber?: number | null
      headquartersAddress?: string | null
      prefecture?: string | null
      offices?: string | null
      logo?: string | null
      coverImage?: string | null
      homepage?: string | null
      industry?: string | null
      businessDescription?: string | null
      businessCategories: Array<string>
      corporatePhilosophy?: string | null
      locations: Array<string>
      workStyleAttraction?: string | null
      members: Array<{
        id: string
        name: string
        jobTitle: string
        bio: string
        avatar?: string | null
      }>
      products: Array<{ id: string; name: string; description: string; image?: string | null }>
    }
    members?: Array<{ name: string; image: string }> | null
    paragraphs?: Array<{ index: number; title: string; body: string }> | null
  } | null
}

export type JobSeekerListJobsQueryVariables = Exact<{
  pagination?: InputMaybe<JobSeekerPaginationInput>
}>

export type JobSeekerListJobsQuery = {
  jobs: {
    data: Array<{
      id: string
      title: string
      description: string
      coverImage?: string | null
      images?: Array<string> | null
      idealCandidate: string
      kind: JobSeekerJobKind
      locations: Array<string>
      targetGrades: Array<JobSeekerJobTargetGrade>
      productInfo?: string | null
      productImages?: Array<string> | null
      company: {
        id: string
        name: string
        foundedOn?: string | null
        employeesNumber?: number | null
        headquartersAddress?: string | null
        prefecture?: string | null
        offices?: string | null
        logo?: string | null
        coverImage?: string | null
        homepage?: string | null
        industry?: string | null
        businessDescription?: string | null
        businessCategories: Array<string>
        corporatePhilosophy?: string | null
        locations: Array<string>
        workStyleAttraction?: string | null
        members: Array<{
          id: string
          name: string
          jobTitle: string
          bio: string
          avatar?: string | null
        }>
        products: Array<{ id: string; name: string; description: string; image?: string | null }>
      }
      members?: Array<{ name: string; image: string }> | null
      paragraphs?: Array<{ index: number; title: string; body: string }> | null
    }>
    pagination: { total: number }
  }
}

export type JobSeekerJobLikesQueryVariables = Exact<{
  sort?: InputMaybe<JobSeekerSortInput>
}>

export type JobSeekerJobLikesQuery = {
  jobLikes: {
    data: Array<{
      id: string
      accepted: boolean
      direction: JobSeekerJobLikeDirection
      job: { id: string; title: string; company: { name: string } }
    }>
  }
}

export type JobSeekerShowCompanyQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type JobSeekerShowCompanyQuery = {
  company?: {
    id: string
    name: string
    foundedOn?: string | null
    employeesNumber?: number | null
    headquartersAddress?: string | null
    prefecture?: string | null
    offices?: string | null
    logo?: string | null
    coverImage?: string | null
    homepage?: string | null
    industry?: string | null
    businessDescription?: string | null
    businessCategories: Array<string>
    corporatePhilosophy?: string | null
    locations: Array<string>
    workStyleAttraction?: string | null
    jobs: Array<{
      id: string
      title: string
      description: string
      coverImage?: string | null
      images?: Array<string> | null
      idealCandidate: string
      kind: JobSeekerJobKind
      locations: Array<string>
      targetGrades: Array<JobSeekerJobTargetGrade>
      productInfo?: string | null
      productImages?: Array<string> | null
      company: {
        id: string
        name: string
        foundedOn?: string | null
        employeesNumber?: number | null
        headquartersAddress?: string | null
        prefecture?: string | null
        offices?: string | null
        logo?: string | null
        coverImage?: string | null
        homepage?: string | null
        industry?: string | null
        businessDescription?: string | null
        businessCategories: Array<string>
        corporatePhilosophy?: string | null
        locations: Array<string>
        workStyleAttraction?: string | null
        members: Array<{
          id: string
          name: string
          jobTitle: string
          bio: string
          avatar?: string | null
        }>
        products: Array<{ id: string; name: string; description: string; image?: string | null }>
      }
      members?: Array<{ name: string; image: string }> | null
      paragraphs?: Array<{ index: number; title: string; body: string }> | null
    }>
    members: Array<{
      id: string
      name: string
      jobTitle: string
      bio: string
      avatar?: string | null
    }>
    products: Array<{ id: string; name: string; description: string; image?: string | null }>
  } | null
}

export type JobSeekerCreateJobLikeMutationVariables = Exact<{
  input: JobSeekerCreateJobLikeInput
}>

export type JobSeekerCreateJobLikeMutation = {
  createJobLike?: { liked: boolean; accepted: boolean } | null
}

export type JobSeekerMarkJobLikesAsViewedByJobSeekerMutationVariables = Exact<{
  ids: Array<Scalars["ID"]> | Scalars["ID"]
}>

export type JobSeekerMarkJobLikesAsViewedByJobSeekerMutation = {
  markJobLikesAsViewedByJobSeeker: { success: boolean }
}

export type JobSeekerCreateJobInterviewMutationVariables = Exact<{
  input: JobSeekerCreateJobInterviewInput
}>

export type JobSeekerCreateJobInterviewMutation = {
  createJobInterview?: { id: string; chatGptThreadId: string } | null
}

export type JobSeekerJobInterviewsAllQueryVariables = Exact<{ [key: string]: never }>

export type JobSeekerJobInterviewsAllQuery = {
  jobInterviewsAll: Array<{
    id: string
    job: { company: { name: string } }
    chatGptThread: { id: string; kind: JobSeekerChatGptThreadKind; createdAt: string }
  }>
}

export type JobSeekerApplyJobInterviewMutationVariables = Exact<{
  input: JobSeekerApplyJobInterviewInput
}>

export type JobSeekerApplyJobInterviewMutation = { applyJobInterview?: { success: boolean } | null }

export type JobSeekerCommonJobSeekerFragment = {
  id: string
  email: string
  phone?: string | null
  firstName: string
  lastName: string
  image?: string | null
  address?: string | null
  placeOfBirth?: string | null
  status: JobSeekerStatus
  birthday?: string | null
  desiredJobTypes: Array<string>
  desiredWorkLocations: Array<string>
  desiredCompanySizes: Array<string>
  careerInterests: Array<string>
  careerInterestsMotivation?: string | null
  studyKeywords: Array<string>
  studyDetail: string
  certifications: Array<string>
  focusSummary?: string | null
  focusDetail?: string | null
  thingsChangedAfterCovid?: string | null
  toeicScore?: number | null
  isRegisteredRequiredProfile: boolean
  educationHistory?: {
    id: string
    name: string
    course: string
    faculty: string
    department: string
    grade: JobSeekerEducationHistoryGrade
    graduatedOn?: string | null
    major: string
    researchDetail?: string | null
    researchSubject?: string | null
    highSchool?: string | null
  } | null
}

export type JobSeekerMySocialAccountsQueryVariables = Exact<{ [key: string]: never }>

export type JobSeekerMySocialAccountsQuery = {
  jobSeekerMe?: {
    id: string
    isInternal: boolean
    hasLinkedLineAccount?: boolean | null
    isFollowingLineAccount?: boolean | null
    socialAccounts: Array<{ provider: JobSeekerSocialAccountProvider; profileUrl: string }>
  } | null
}

export type JobSeekerMyAchievementsQueryVariables = Exact<{ [key: string]: never }>

export type JobSeekerMyAchievementsQuery = {
  jobSeekerMe?: {
    id: string
    achievements: Array<{
      id: string
      title: string
      url?: string | null
      description: string
      date: string
      attachment?: string | null
    }>
  } | null
}

export type JobSeekerMeQueryVariables = Exact<{ [key: string]: never }>

export type JobSeekerMeQuery = {
  jobSeekerMe?: {
    introducedByName?: string | null
    isInternal: boolean
    slug: string
    bio?: string | null
    id: string
    email: string
    phone?: string | null
    firstName: string
    lastName: string
    image?: string | null
    address?: string | null
    placeOfBirth?: string | null
    status: JobSeekerStatus
    birthday?: string | null
    desiredJobTypes: Array<string>
    desiredWorkLocations: Array<string>
    desiredCompanySizes: Array<string>
    careerInterests: Array<string>
    careerInterestsMotivation?: string | null
    studyKeywords: Array<string>
    studyDetail: string
    certifications: Array<string>
    focusSummary?: string | null
    focusDetail?: string | null
    thingsChangedAfterCovid?: string | null
    toeicScore?: number | null
    isRegisteredRequiredProfile: boolean
    educationHistory?: {
      id: string
      name: string
      course: string
      faculty: string
      department: string
      grade: JobSeekerEducationHistoryGrade
      graduatedOn?: string | null
      major: string
      researchDetail?: string | null
      researchSubject?: string | null
      highSchool?: string | null
    } | null
  } | null
}

export type JobSeekerMeForRikelinkQueryVariables = Exact<{ [key: string]: never }>

export type JobSeekerMeForRikelinkQuery = {
  jobSeekerMe?: {
    id: string
    bio?: string | null
    slug: string
    emailForProfile?: string | null
    firstName: string
    lastName: string
    image?: string | null
    address?: string | null
    placeOfBirth?: string | null
    status: JobSeekerStatus
    birthday?: string | null
    desiredJobTypes: Array<string>
    desiredWorkLocations: Array<string>
    desiredCompanySizes: Array<string>
    careerInterests: Array<string>
    studyKeywords: Array<string>
    studyDetail: string
    certifications: Array<string>
    focusSummary?: string | null
    focusDetail?: string | null
    thingsChangedAfterCovid?: string | null
    toeicScore?: number | null
    socialAccounts: Array<{ provider: JobSeekerSocialAccountProvider; profileUrl: string }>
    educationHistory?: {
      id: string
      name: string
      course: string
      faculty: string
      department: string
      grade: JobSeekerEducationHistoryGrade
      graduatedOn?: string | null
      major: string
      researchDetail?: string | null
      researchSubject?: string | null
      highSchool?: string | null
    } | null
    achievements: Array<{
      id: string
      title: string
      url?: string | null
      description: string
      date: string
      attachment?: string | null
    }>
  } | null
}

export type JobSeekerBySlugQueryVariables = Exact<{
  slug: Scalars["String"]
}>

export type JobSeekerBySlugQuery = {
  jobSeekerBySlug: {
    id: string
    bio?: string | null
    slug: string
    emailForProfile?: string | null
    firstName: string
    lastName: string
    image?: string | null
    address?: string | null
    placeOfBirth?: string | null
    status: JobSeekerStatus
    birthday?: string | null
    desiredJobTypes: Array<string>
    desiredWorkLocations: Array<string>
    desiredCompanySizes: Array<string>
    careerInterests: Array<string>
    studyKeywords: Array<string>
    studyDetail: string
    certifications: Array<string>
    focusSummary?: string | null
    focusDetail?: string | null
    thingsChangedAfterCovid?: string | null
    toeicScore?: number | null
    socialAccounts: Array<{ provider: JobSeekerSocialAccountProvider; profileUrl: string }>
    educationHistory?: {
      id: string
      name: string
      course: string
      faculty: string
      department: string
      grade: JobSeekerEducationHistoryGrade
      graduatedOn?: string | null
      major: string
      researchDetail?: string | null
      researchSubject?: string | null
      highSchool?: string | null
    } | null
    achievements: Array<{
      id: string
      title: string
      url?: string | null
      description: string
      date: string
      attachment?: string | null
    }>
  }
}

export type JobSeekerBySlugForReferralCommentsQueryVariables = Exact<{
  slug: Scalars["String"]
}>

export type JobSeekerBySlugForReferralCommentsQuery = {
  jobSeekerBySlug: {
    id: string
    image?: string | null
    firstName: string
    lastName: string
    educationHistory?: {
      name: string
      course: string
      faculty: string
      department: string
      grade: JobSeekerEducationHistoryGrade
      major: string
    } | null
  }
}

export type JobSeekerUpdateProfileMutationVariables = Exact<{
  input: JobSeekerUpdateJobSeekerInput
}>

export type JobSeekerUpdateProfileMutation = {
  updateJobSeeker?: {
    id: string
    email: string
    phone?: string | null
    firstName: string
    lastName: string
    image?: string | null
    address?: string | null
    placeOfBirth?: string | null
    status: JobSeekerStatus
    birthday?: string | null
    desiredJobTypes: Array<string>
    desiredWorkLocations: Array<string>
    desiredCompanySizes: Array<string>
    careerInterests: Array<string>
    careerInterestsMotivation?: string | null
    studyKeywords: Array<string>
    studyDetail: string
    certifications: Array<string>
    focusSummary?: string | null
    focusDetail?: string | null
    thingsChangedAfterCovid?: string | null
    toeicScore?: number | null
    isRegisteredRequiredProfile: boolean
    educationHistory?: {
      id: string
      name: string
      course: string
      faculty: string
      department: string
      grade: JobSeekerEducationHistoryGrade
      graduatedOn?: string | null
      major: string
      researchDetail?: string | null
      researchSubject?: string | null
      highSchool?: string | null
    } | null
  } | null
}

export type JobSeekerAssociateSocialAccountMutationVariables = Exact<{
  input: JobSeekerAssociateSocialAccountInput
}>

export type JobSeekerAssociateSocialAccountMutation = {
  associateSocialAccount?: { provider: JobSeekerSocialAccountProvider; profileUrl: string } | null
}

export type JobSeekerDisassociateSocialAccountMutationVariables = Exact<{
  provider: JobSeekerSocialAccountProvider
}>

export type JobSeekerDisassociateSocialAccountMutation = {
  disassociateSocialAccount: { success: boolean }
}

export type JobSeekerCreateAchievementMutationVariables = Exact<{
  input: JobSeekerAchievementInput
}>

export type JobSeekerCreateAchievementMutation = {
  createAchievement?: {
    id: string
    title: string
    url?: string | null
    description: string
    date: string
    attachment?: string | null
  } | null
}

export type JobSeekerUpdateAchievementMutationVariables = Exact<{
  id: Scalars["String"]
  input: JobSeekerAchievementInput
}>

export type JobSeekerUpdateAchievementMutation = {
  updateAchievement: {
    id: string
    title: string
    url?: string | null
    description: string
    date: string
    attachment?: string | null
  }
}

export type JobSeekerDeleteAchievementMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type JobSeekerDeleteAchievementMutation = { deleteAchievement: { success: boolean } }

export type JobSeekerUnreadItemsCountQueryVariables = Exact<{ [key: string]: never }>

export type JobSeekerUnreadItemsCountQuery = {
  unreadItemsCount: { jobLikes: number; sentScouts: number; messages: number }
}

export type JobSeekerShowJobSeekerPrivacySettingQueryVariables = Exact<{ [key: string]: never }>

export type JobSeekerShowJobSeekerPrivacySettingQuery = {
  jobSeekerPrivacySetting?: {
    allowEmailPublication: boolean
    allowProfilePublication: boolean
    allowProfileAccessByCompanies: boolean
  } | null
}

export type JobSeekerUpdateJobSeekerPrivacySettingMutationVariables = Exact<{
  input: JobSeekerUpdateJobSeekerPrivacySettingInput
}>

export type JobSeekerUpdateJobSeekerPrivacySettingMutation = {
  updateJobSeekerPrivacySetting: {
    allowEmailPublication: boolean
    allowProfilePublication: boolean
    allowProfileAccessByCompanies: boolean
  }
}

export type JobSeekerCommonMemberFragment = {
  id: string
  name: string
  jobTitle: string
  bio: string
  avatar?: string | null
  messageToStudents?: string | null
}

export type JobSeekerListMembersQueryVariables = Exact<{ [key: string]: never }>

export type JobSeekerListMembersQuery = {
  members: Array<{
    id: string
    name: string
    jobTitle: string
    bio: string
    avatar?: string | null
    messageToStudents?: string | null
  }>
}

export type JobSeekerShowMemberQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type JobSeekerShowMemberQuery = {
  member?: {
    id: string
    name: string
    jobTitle: string
    bio: string
    avatar?: string | null
    messageToStudents?: string | null
    company: { name: string }
  } | null
}

export type JobSeekerMessageThreadsQueryVariables = Exact<{ [key: string]: never }>

export type JobSeekerMessageThreadsQuery = {
  messageThreads: {
    data: Array<{
      id: string
      canAcceptScout: boolean
      canPostMessage: boolean
      origin: JobSeekerMessageThreadOrigin
      job: { id: string }
      company: { name: string; logo?: string | null }
      latestMessage?: { createdAt: string; text: string } | null
    }>
  }
}

export type JobSeekerMessageThreadQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type JobSeekerMessageThreadQuery = {
  messageThread?: {
    origin: JobSeekerMessageThreadOrigin
    isLike: boolean
    canAcceptScout: boolean
    canPostMessage: boolean
    company: { name: string; logo?: string | null }
    job: { id: string }
    messages: Array<{
      id: string
      text: string
      kind: JobSeekerMessageKind
      createdAt: string
      jobSeeker?: {
        id: string
        image?: string | null
        firstName: string
        lastName: string
        fullName: string
      } | null
      companyStaff?: {
        id: string
        firstName: string
        lastName: string
        fullName: string
        company: { name: string }
      } | null
    }>
  } | null
}

export type JobSeekerPostMessageMutationVariables = Exact<{
  text: Scalars["String"]
  threadId: Scalars["String"]
}>

export type JobSeekerPostMessageMutation = {
  postMessage?: { id: string; messages: Array<{ id: string }> } | null
}

export type JobSeekerAcceptScoutMutationVariables = Exact<{
  threadId: Scalars["String"]
}>

export type JobSeekerAcceptScoutMutation = { acceptScout?: { id: string } | null }

export type JobSeekerCommonProductFragment = {
  id: string
  name: string
  description: string
  image?: string | null
}

export type JobSeekerListProductsQueryVariables = Exact<{ [key: string]: never }>

export type JobSeekerListProductsQuery = {
  products: Array<{ id: string; name: string; description: string; image?: string | null }>
}

export type JobSeekerShowProductQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type JobSeekerShowProductQuery = {
  product?: { id: string; name: string; description: string; image?: string | null } | null
}

export type JobSeekerCreateReferralCommentMutationVariables = Exact<{
  input: JobSeekerCreateReferralCommentInput
}>

export type JobSeekerCreateReferralCommentMutation = {
  createReferralComment: { id: string; name: string }
}

export type JobSeekerReferralCommentsQueryVariables = Exact<{
  slug: Scalars["String"]
}>

export type JobSeekerReferralCommentsQuery = {
  referralComments: Array<{
    id: string
    name: string
    comment: string
    organization: string
    relationship: string
    createdAt: string
    avatar?: string | null
  }>
}

export const JobSeekerCommonCompanyFragmentDoc = gql`
    fragment CommonCompany on Company {
  id
  name
  foundedOn
  employeesNumber
  headquartersAddress
  prefecture
  offices
  logo
  coverImage
  homepage
  industry
  businessDescription
  businessCategories
  corporatePhilosophy
  locations
  members {
    id
    name
    jobTitle
    bio
    avatar
  }
  products {
    id
    name
    description
    image
  }
  workStyleAttraction
}
    `
export const JobSeekerCommonJobFragmentDoc = gql`
    fragment CommonJob on Job {
  id
  title
  description
  company {
    ...CommonCompany
  }
  coverImage
  images
  members {
    name
    image
  }
  idealCandidate
  kind
  locations
  targetGrades
  productInfo
  productImages
  paragraphs {
    index
    title
    body
  }
}
    `
export const JobSeekerCommonJobSeekerFragmentDoc = gql`
    fragment CommonJobSeeker on JobSeeker {
  id
  email
  phone
  firstName
  lastName
  image
  address
  placeOfBirth
  status
  birthday
  desiredJobTypes
  desiredWorkLocations
  desiredCompanySizes
  careerInterests
  careerInterestsMotivation
  studyKeywords
  studyDetail
  certifications
  focusSummary
  focusDetail
  thingsChangedAfterCovid
  toeicScore
  educationHistory {
    id
    name
    course
    faculty
    department
    grade
    graduatedOn
    major
    researchDetail
    researchSubject
    highSchool
  }
  isRegisteredRequiredProfile
}
    `
export const JobSeekerCommonMemberFragmentDoc = gql`
    fragment CommonMember on Member {
  id
  name
  jobTitle
  bio
  avatar
  messageToStudents
}
    `
export const JobSeekerCommonProductFragmentDoc = gql`
    fragment CommonProduct on Product {
  id
  name
  description
  image
}
    `
export const JobSeekerCreateAiInterviewHistoryDocument = gql`
    mutation CreateAiInterviewHistory($input: CreateAiInterviewHistoryInput!) {
  createAiInterviewHistory(input: $input) {
    success
  }
}
    `
export type JobSeekerCreateAiInterviewHistoryMutationFn = Apollo.MutationFunction<
  JobSeekerCreateAiInterviewHistoryMutation,
  JobSeekerCreateAiInterviewHistoryMutationVariables
>

/**
 * __useJobSeekerCreateAiInterviewHistoryMutation__
 *
 * To run a mutation, you first call `useJobSeekerCreateAiInterviewHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerCreateAiInterviewHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerCreateAiInterviewHistoryMutation, { data, loading, error }] = useJobSeekerCreateAiInterviewHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobSeekerCreateAiInterviewHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerCreateAiInterviewHistoryMutation,
    JobSeekerCreateAiInterviewHistoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerCreateAiInterviewHistoryMutation,
    JobSeekerCreateAiInterviewHistoryMutationVariables
  >(JobSeekerCreateAiInterviewHistoryDocument, options)
}
export type JobSeekerCreateAiInterviewHistoryMutationHookResult = ReturnType<
  typeof useJobSeekerCreateAiInterviewHistoryMutation
>
export type JobSeekerCreateAiInterviewHistoryMutationResult =
  Apollo.MutationResult<JobSeekerCreateAiInterviewHistoryMutation>
export type JobSeekerCreateAiInterviewHistoryMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerCreateAiInterviewHistoryMutation,
  JobSeekerCreateAiInterviewHistoryMutationVariables
>
export const JobSeekerAiInterviewHistoriesDocument = gql`
    query AiInterviewHistories {
  aiInterviewHistories {
    id
    chatGptThread {
      id
      kind
      createdAt
    }
    createdAt
  }
}
    `

/**
 * __useJobSeekerAiInterviewHistoriesQuery__
 *
 * To run a query within a React component, call `useJobSeekerAiInterviewHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerAiInterviewHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerAiInterviewHistoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSeekerAiInterviewHistoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerAiInterviewHistoriesQuery,
    JobSeekerAiInterviewHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<
    JobSeekerAiInterviewHistoriesQuery,
    JobSeekerAiInterviewHistoriesQueryVariables
  >(JobSeekerAiInterviewHistoriesDocument, options)
}
export function useJobSeekerAiInterviewHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerAiInterviewHistoriesQuery,
    JobSeekerAiInterviewHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<
    JobSeekerAiInterviewHistoriesQuery,
    JobSeekerAiInterviewHistoriesQueryVariables
  >(JobSeekerAiInterviewHistoriesDocument, options)
}
export type JobSeekerAiInterviewHistoriesQueryHookResult = ReturnType<
  typeof useJobSeekerAiInterviewHistoriesQuery
>
export type JobSeekerAiInterviewHistoriesLazyQueryHookResult = ReturnType<
  typeof useJobSeekerAiInterviewHistoriesLazyQuery
>
export type JobSeekerAiInterviewHistoriesQueryResult = Apollo.QueryResult<
  JobSeekerAiInterviewHistoriesQuery,
  JobSeekerAiInterviewHistoriesQueryVariables
>
export const JobSeekerAiInterviewHistoryDocument = gql`
    query AiInterviewHistory($chatGptThreadId: ID!) {
  aiInterviewHistory(chatGptThreadId: $chatGptThreadId) {
    id
    chatGptThread {
      id
      kind
      createdAt
    }
    createdAt
  }
}
    `

/**
 * __useJobSeekerAiInterviewHistoryQuery__
 *
 * To run a query within a React component, call `useJobSeekerAiInterviewHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerAiInterviewHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerAiInterviewHistoryQuery({
 *   variables: {
 *      chatGptThreadId: // value for 'chatGptThreadId'
 *   },
 * });
 */
export function useJobSeekerAiInterviewHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobSeekerAiInterviewHistoryQuery,
    JobSeekerAiInterviewHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<
    JobSeekerAiInterviewHistoryQuery,
    JobSeekerAiInterviewHistoryQueryVariables
  >(JobSeekerAiInterviewHistoryDocument, options)
}
export function useJobSeekerAiInterviewHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerAiInterviewHistoryQuery,
    JobSeekerAiInterviewHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<
    JobSeekerAiInterviewHistoryQuery,
    JobSeekerAiInterviewHistoryQueryVariables
  >(JobSeekerAiInterviewHistoryDocument, options)
}
export type JobSeekerAiInterviewHistoryQueryHookResult = ReturnType<
  typeof useJobSeekerAiInterviewHistoryQuery
>
export type JobSeekerAiInterviewHistoryLazyQueryHookResult = ReturnType<
  typeof useJobSeekerAiInterviewHistoryLazyQuery
>
export type JobSeekerAiInterviewHistoryQueryResult = Apollo.QueryResult<
  JobSeekerAiInterviewHistoryQuery,
  JobSeekerAiInterviewHistoryQueryVariables
>
export const JobSeekerDeleteAiInterviewHistoryDocument = gql`
    mutation DeleteAiInterviewHistory($input: DeleteAiInterviewHistoryInput!) {
  deleteAiInterviewHistory(input: $input) {
    success
  }
}
    `
export type JobSeekerDeleteAiInterviewHistoryMutationFn = Apollo.MutationFunction<
  JobSeekerDeleteAiInterviewHistoryMutation,
  JobSeekerDeleteAiInterviewHistoryMutationVariables
>

/**
 * __useJobSeekerDeleteAiInterviewHistoryMutation__
 *
 * To run a mutation, you first call `useJobSeekerDeleteAiInterviewHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerDeleteAiInterviewHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerDeleteAiInterviewHistoryMutation, { data, loading, error }] = useJobSeekerDeleteAiInterviewHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobSeekerDeleteAiInterviewHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerDeleteAiInterviewHistoryMutation,
    JobSeekerDeleteAiInterviewHistoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerDeleteAiInterviewHistoryMutation,
    JobSeekerDeleteAiInterviewHistoryMutationVariables
  >(JobSeekerDeleteAiInterviewHistoryDocument, options)
}
export type JobSeekerDeleteAiInterviewHistoryMutationHookResult = ReturnType<
  typeof useJobSeekerDeleteAiInterviewHistoryMutation
>
export type JobSeekerDeleteAiInterviewHistoryMutationResult =
  Apollo.MutationResult<JobSeekerDeleteAiInterviewHistoryMutation>
export type JobSeekerDeleteAiInterviewHistoryMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerDeleteAiInterviewHistoryMutation,
  JobSeekerDeleteAiInterviewHistoryMutationVariables
>
export const JobSeekerLatestCampaignDocument = gql`
    query LatestCampaign {
  latestCampaign {
    id
    name
    code
    startsAt
    endsAt
    viewed
  }
}
    `

/**
 * __useJobSeekerLatestCampaignQuery__
 *
 * To run a query within a React component, call `useJobSeekerLatestCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerLatestCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerLatestCampaignQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSeekerLatestCampaignQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerLatestCampaignQuery,
    JobSeekerLatestCampaignQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerLatestCampaignQuery, JobSeekerLatestCampaignQueryVariables>(
    JobSeekerLatestCampaignDocument,
    options,
  )
}
export function useJobSeekerLatestCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerLatestCampaignQuery,
    JobSeekerLatestCampaignQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerLatestCampaignQuery, JobSeekerLatestCampaignQueryVariables>(
    JobSeekerLatestCampaignDocument,
    options,
  )
}
export type JobSeekerLatestCampaignQueryHookResult = ReturnType<
  typeof useJobSeekerLatestCampaignQuery
>
export type JobSeekerLatestCampaignLazyQueryHookResult = ReturnType<
  typeof useJobSeekerLatestCampaignLazyQuery
>
export type JobSeekerLatestCampaignQueryResult = Apollo.QueryResult<
  JobSeekerLatestCampaignQuery,
  JobSeekerLatestCampaignQueryVariables
>
export const JobSeekerLatestCampaignsDocument = gql`
    query LatestCampaigns {
  latestCampaigns {
    id
    name
    code
    startsAt
    endsAt
    viewed
  }
}
    `

/**
 * __useJobSeekerLatestCampaignsQuery__
 *
 * To run a query within a React component, call `useJobSeekerLatestCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerLatestCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerLatestCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSeekerLatestCampaignsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerLatestCampaignsQuery,
    JobSeekerLatestCampaignsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerLatestCampaignsQuery, JobSeekerLatestCampaignsQueryVariables>(
    JobSeekerLatestCampaignsDocument,
    options,
  )
}
export function useJobSeekerLatestCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerLatestCampaignsQuery,
    JobSeekerLatestCampaignsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerLatestCampaignsQuery, JobSeekerLatestCampaignsQueryVariables>(
    JobSeekerLatestCampaignsDocument,
    options,
  )
}
export type JobSeekerLatestCampaignsQueryHookResult = ReturnType<
  typeof useJobSeekerLatestCampaignsQuery
>
export type JobSeekerLatestCampaignsLazyQueryHookResult = ReturnType<
  typeof useJobSeekerLatestCampaignsLazyQuery
>
export type JobSeekerLatestCampaignsQueryResult = Apollo.QueryResult<
  JobSeekerLatestCampaignsQuery,
  JobSeekerLatestCampaignsQueryVariables
>
export const JobSeekerCreateUserCampaignViewDocument = gql`
    mutation CreateUserCampaignView($input: CreateUserCampaignViewInput!) {
  createUserCampaignView(input: $input) {
    id
  }
}
    `
export type JobSeekerCreateUserCampaignViewMutationFn = Apollo.MutationFunction<
  JobSeekerCreateUserCampaignViewMutation,
  JobSeekerCreateUserCampaignViewMutationVariables
>

/**
 * __useJobSeekerCreateUserCampaignViewMutation__
 *
 * To run a mutation, you first call `useJobSeekerCreateUserCampaignViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerCreateUserCampaignViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerCreateUserCampaignViewMutation, { data, loading, error }] = useJobSeekerCreateUserCampaignViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobSeekerCreateUserCampaignViewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerCreateUserCampaignViewMutation,
    JobSeekerCreateUserCampaignViewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerCreateUserCampaignViewMutation,
    JobSeekerCreateUserCampaignViewMutationVariables
  >(JobSeekerCreateUserCampaignViewDocument, options)
}
export type JobSeekerCreateUserCampaignViewMutationHookResult = ReturnType<
  typeof useJobSeekerCreateUserCampaignViewMutation
>
export type JobSeekerCreateUserCampaignViewMutationResult =
  Apollo.MutationResult<JobSeekerCreateUserCampaignViewMutation>
export type JobSeekerCreateUserCampaignViewMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerCreateUserCampaignViewMutation,
  JobSeekerCreateUserCampaignViewMutationVariables
>
export const JobSeekerAskChatGptDocument = gql`
    query AskChatGpt($question: String!, $systemContent: String) {
  askChatGpt(question: $question, systemContent: $systemContent) {
    answer
  }
}
    `

/**
 * __useJobSeekerAskChatGptQuery__
 *
 * To run a query within a React component, call `useJobSeekerAskChatGptQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerAskChatGptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerAskChatGptQuery({
 *   variables: {
 *      question: // value for 'question'
 *      systemContent: // value for 'systemContent'
 *   },
 * });
 */
export function useJobSeekerAskChatGptQuery(
  baseOptions: Apollo.QueryHookOptions<JobSeekerAskChatGptQuery, JobSeekerAskChatGptQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerAskChatGptQuery, JobSeekerAskChatGptQueryVariables>(
    JobSeekerAskChatGptDocument,
    options,
  )
}
export function useJobSeekerAskChatGptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerAskChatGptQuery,
    JobSeekerAskChatGptQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerAskChatGptQuery, JobSeekerAskChatGptQueryVariables>(
    JobSeekerAskChatGptDocument,
    options,
  )
}
export type JobSeekerAskChatGptQueryHookResult = ReturnType<typeof useJobSeekerAskChatGptQuery>
export type JobSeekerAskChatGptLazyQueryHookResult = ReturnType<
  typeof useJobSeekerAskChatGptLazyQuery
>
export type JobSeekerAskChatGptQueryResult = Apollo.QueryResult<
  JobSeekerAskChatGptQuery,
  JobSeekerAskChatGptQueryVariables
>
export const JobSeekerCreateChatGptMessageDocument = gql`
    mutation CreateChatGptMessage($input: CreateChatGptMessageInput!) {
  createChatGptMessage(input: $input) {
    id
    kind
    messages {
      id
      content
      role
      inputSource
    }
  }
}
    `
export type JobSeekerCreateChatGptMessageMutationFn = Apollo.MutationFunction<
  JobSeekerCreateChatGptMessageMutation,
  JobSeekerCreateChatGptMessageMutationVariables
>

/**
 * __useJobSeekerCreateChatGptMessageMutation__
 *
 * To run a mutation, you first call `useJobSeekerCreateChatGptMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerCreateChatGptMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerCreateChatGptMessageMutation, { data, loading, error }] = useJobSeekerCreateChatGptMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobSeekerCreateChatGptMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerCreateChatGptMessageMutation,
    JobSeekerCreateChatGptMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerCreateChatGptMessageMutation,
    JobSeekerCreateChatGptMessageMutationVariables
  >(JobSeekerCreateChatGptMessageDocument, options)
}
export type JobSeekerCreateChatGptMessageMutationHookResult = ReturnType<
  typeof useJobSeekerCreateChatGptMessageMutation
>
export type JobSeekerCreateChatGptMessageMutationResult =
  Apollo.MutationResult<JobSeekerCreateChatGptMessageMutation>
export type JobSeekerCreateChatGptMessageMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerCreateChatGptMessageMutation,
  JobSeekerCreateChatGptMessageMutationVariables
>
export const JobSeekerStoreChatGptMessagesDocument = gql`
    mutation StoreChatGptMessages($input: StoreChatGptMessagesInput!) {
  storeChatGptMessages(input: $input) {
    id
    kind
    messages {
      id
      content
      role
      inputSource
    }
  }
}
    `
export type JobSeekerStoreChatGptMessagesMutationFn = Apollo.MutationFunction<
  JobSeekerStoreChatGptMessagesMutation,
  JobSeekerStoreChatGptMessagesMutationVariables
>

/**
 * __useJobSeekerStoreChatGptMessagesMutation__
 *
 * To run a mutation, you first call `useJobSeekerStoreChatGptMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerStoreChatGptMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerStoreChatGptMessagesMutation, { data, loading, error }] = useJobSeekerStoreChatGptMessagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobSeekerStoreChatGptMessagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerStoreChatGptMessagesMutation,
    JobSeekerStoreChatGptMessagesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerStoreChatGptMessagesMutation,
    JobSeekerStoreChatGptMessagesMutationVariables
  >(JobSeekerStoreChatGptMessagesDocument, options)
}
export type JobSeekerStoreChatGptMessagesMutationHookResult = ReturnType<
  typeof useJobSeekerStoreChatGptMessagesMutation
>
export type JobSeekerStoreChatGptMessagesMutationResult =
  Apollo.MutationResult<JobSeekerStoreChatGptMessagesMutation>
export type JobSeekerStoreChatGptMessagesMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerStoreChatGptMessagesMutation,
  JobSeekerStoreChatGptMessagesMutationVariables
>
export const JobSeekerCreateChatGptThreadDocument = gql`
    mutation CreateChatGptThread($input: CreateChatGptThreadInput!) {
  createChatGptThread(input: $input) {
    id
    kind
    messages {
      id
      content
      role
      inputSource
    }
  }
}
    `
export type JobSeekerCreateChatGptThreadMutationFn = Apollo.MutationFunction<
  JobSeekerCreateChatGptThreadMutation,
  JobSeekerCreateChatGptThreadMutationVariables
>

/**
 * __useJobSeekerCreateChatGptThreadMutation__
 *
 * To run a mutation, you first call `useJobSeekerCreateChatGptThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerCreateChatGptThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerCreateChatGptThreadMutation, { data, loading, error }] = useJobSeekerCreateChatGptThreadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobSeekerCreateChatGptThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerCreateChatGptThreadMutation,
    JobSeekerCreateChatGptThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerCreateChatGptThreadMutation,
    JobSeekerCreateChatGptThreadMutationVariables
  >(JobSeekerCreateChatGptThreadDocument, options)
}
export type JobSeekerCreateChatGptThreadMutationHookResult = ReturnType<
  typeof useJobSeekerCreateChatGptThreadMutation
>
export type JobSeekerCreateChatGptThreadMutationResult =
  Apollo.MutationResult<JobSeekerCreateChatGptThreadMutation>
export type JobSeekerCreateChatGptThreadMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerCreateChatGptThreadMutation,
  JobSeekerCreateChatGptThreadMutationVariables
>
export const JobSeekerChatGptThreadDocument = gql`
    query ChatGptThread($id: ID!) {
  chatGptThread(id: $id) {
    id
    kind
    messages {
      id
      content
      role
      inputSource
    }
  }
}
    `

/**
 * __useJobSeekerChatGptThreadQuery__
 *
 * To run a query within a React component, call `useJobSeekerChatGptThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerChatGptThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerChatGptThreadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobSeekerChatGptThreadQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobSeekerChatGptThreadQuery,
    JobSeekerChatGptThreadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerChatGptThreadQuery, JobSeekerChatGptThreadQueryVariables>(
    JobSeekerChatGptThreadDocument,
    options,
  )
}
export function useJobSeekerChatGptThreadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerChatGptThreadQuery,
    JobSeekerChatGptThreadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerChatGptThreadQuery, JobSeekerChatGptThreadQueryVariables>(
    JobSeekerChatGptThreadDocument,
    options,
  )
}
export type JobSeekerChatGptThreadQueryHookResult = ReturnType<
  typeof useJobSeekerChatGptThreadQuery
>
export type JobSeekerChatGptThreadLazyQueryHookResult = ReturnType<
  typeof useJobSeekerChatGptThreadLazyQuery
>
export type JobSeekerChatGptThreadQueryResult = Apollo.QueryResult<
  JobSeekerChatGptThreadQuery,
  JobSeekerChatGptThreadQueryVariables
>
export const JobSeekerAiInterviewAvailabilityDocument = gql`
    query AiInterviewAvailability($jobId: ID) {
  aiInterviewAvailability(jobId: $jobId) {
    availableInterviewsCount
    availableJobInterviewsCount
    availableForJob
    incompleteProfileFieldErrors
  }
}
    `

/**
 * __useJobSeekerAiInterviewAvailabilityQuery__
 *
 * To run a query within a React component, call `useJobSeekerAiInterviewAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerAiInterviewAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerAiInterviewAvailabilityQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobSeekerAiInterviewAvailabilityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerAiInterviewAvailabilityQuery,
    JobSeekerAiInterviewAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<
    JobSeekerAiInterviewAvailabilityQuery,
    JobSeekerAiInterviewAvailabilityQueryVariables
  >(JobSeekerAiInterviewAvailabilityDocument, options)
}
export function useJobSeekerAiInterviewAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerAiInterviewAvailabilityQuery,
    JobSeekerAiInterviewAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<
    JobSeekerAiInterviewAvailabilityQuery,
    JobSeekerAiInterviewAvailabilityQueryVariables
  >(JobSeekerAiInterviewAvailabilityDocument, options)
}
export type JobSeekerAiInterviewAvailabilityQueryHookResult = ReturnType<
  typeof useJobSeekerAiInterviewAvailabilityQuery
>
export type JobSeekerAiInterviewAvailabilityLazyQueryHookResult = ReturnType<
  typeof useJobSeekerAiInterviewAvailabilityLazyQuery
>
export type JobSeekerAiInterviewAvailabilityQueryResult = Apollo.QueryResult<
  JobSeekerAiInterviewAvailabilityQuery,
  JobSeekerAiInterviewAvailabilityQueryVariables
>
export const JobSeekerChatGptMessagesDocument = gql`
    query ChatGptMessages($threadId: ID!) {
  chatGptMessages(threadId: $threadId) {
    id
    role
    content
    inputSource
    createdAt
  }
}
    `

/**
 * __useJobSeekerChatGptMessagesQuery__
 *
 * To run a query within a React component, call `useJobSeekerChatGptMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerChatGptMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerChatGptMessagesQuery({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useJobSeekerChatGptMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobSeekerChatGptMessagesQuery,
    JobSeekerChatGptMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerChatGptMessagesQuery, JobSeekerChatGptMessagesQueryVariables>(
    JobSeekerChatGptMessagesDocument,
    options,
  )
}
export function useJobSeekerChatGptMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerChatGptMessagesQuery,
    JobSeekerChatGptMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerChatGptMessagesQuery, JobSeekerChatGptMessagesQueryVariables>(
    JobSeekerChatGptMessagesDocument,
    options,
  )
}
export type JobSeekerChatGptMessagesQueryHookResult = ReturnType<
  typeof useJobSeekerChatGptMessagesQuery
>
export type JobSeekerChatGptMessagesLazyQueryHookResult = ReturnType<
  typeof useJobSeekerChatGptMessagesLazyQuery
>
export type JobSeekerChatGptMessagesQueryResult = Apollo.QueryResult<
  JobSeekerChatGptMessagesQuery,
  JobSeekerChatGptMessagesQueryVariables
>
export const JobSeekerChatGptThreadsDocument = gql`
    query ChatGptThreads {
  chatGptThreads {
    id
    kind
    createdAt
  }
}
    `

/**
 * __useJobSeekerChatGptThreadsQuery__
 *
 * To run a query within a React component, call `useJobSeekerChatGptThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerChatGptThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerChatGptThreadsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSeekerChatGptThreadsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerChatGptThreadsQuery,
    JobSeekerChatGptThreadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerChatGptThreadsQuery, JobSeekerChatGptThreadsQueryVariables>(
    JobSeekerChatGptThreadsDocument,
    options,
  )
}
export function useJobSeekerChatGptThreadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerChatGptThreadsQuery,
    JobSeekerChatGptThreadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerChatGptThreadsQuery, JobSeekerChatGptThreadsQueryVariables>(
    JobSeekerChatGptThreadsDocument,
    options,
  )
}
export type JobSeekerChatGptThreadsQueryHookResult = ReturnType<
  typeof useJobSeekerChatGptThreadsQuery
>
export type JobSeekerChatGptThreadsLazyQueryHookResult = ReturnType<
  typeof useJobSeekerChatGptThreadsLazyQuery
>
export type JobSeekerChatGptThreadsQueryResult = Apollo.QueryResult<
  JobSeekerChatGptThreadsQuery,
  JobSeekerChatGptThreadsQueryVariables
>
export const JobSeekerEducationalInstitutionsDocument = gql`
    query EducationalInstitutions {
  educationalInstitutions {
    id
    name
  }
}
    `

/**
 * __useJobSeekerEducationalInstitutionsQuery__
 *
 * To run a query within a React component, call `useJobSeekerEducationalInstitutionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerEducationalInstitutionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerEducationalInstitutionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSeekerEducationalInstitutionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerEducationalInstitutionsQuery,
    JobSeekerEducationalInstitutionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<
    JobSeekerEducationalInstitutionsQuery,
    JobSeekerEducationalInstitutionsQueryVariables
  >(JobSeekerEducationalInstitutionsDocument, options)
}
export function useJobSeekerEducationalInstitutionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerEducationalInstitutionsQuery,
    JobSeekerEducationalInstitutionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<
    JobSeekerEducationalInstitutionsQuery,
    JobSeekerEducationalInstitutionsQueryVariables
  >(JobSeekerEducationalInstitutionsDocument, options)
}
export type JobSeekerEducationalInstitutionsQueryHookResult = ReturnType<
  typeof useJobSeekerEducationalInstitutionsQuery
>
export type JobSeekerEducationalInstitutionsLazyQueryHookResult = ReturnType<
  typeof useJobSeekerEducationalInstitutionsLazyQuery
>
export type JobSeekerEducationalInstitutionsQueryResult = Apollo.QueryResult<
  JobSeekerEducationalInstitutionsQuery,
  JobSeekerEducationalInstitutionsQueryVariables
>
export const JobSeekerEducationalInstitutionDocument = gql`
    query EducationalInstitution($name: String!) {
  educationalInstitution(name: $name) {
    id
    name
  }
}
    `

/**
 * __useJobSeekerEducationalInstitutionQuery__
 *
 * To run a query within a React component, call `useJobSeekerEducationalInstitutionQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerEducationalInstitutionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerEducationalInstitutionQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useJobSeekerEducationalInstitutionQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobSeekerEducationalInstitutionQuery,
    JobSeekerEducationalInstitutionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<
    JobSeekerEducationalInstitutionQuery,
    JobSeekerEducationalInstitutionQueryVariables
  >(JobSeekerEducationalInstitutionDocument, options)
}
export function useJobSeekerEducationalInstitutionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerEducationalInstitutionQuery,
    JobSeekerEducationalInstitutionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<
    JobSeekerEducationalInstitutionQuery,
    JobSeekerEducationalInstitutionQueryVariables
  >(JobSeekerEducationalInstitutionDocument, options)
}
export type JobSeekerEducationalInstitutionQueryHookResult = ReturnType<
  typeof useJobSeekerEducationalInstitutionQuery
>
export type JobSeekerEducationalInstitutionLazyQueryHookResult = ReturnType<
  typeof useJobSeekerEducationalInstitutionLazyQuery
>
export type JobSeekerEducationalInstitutionQueryResult = Apollo.QueryResult<
  JobSeekerEducationalInstitutionQuery,
  JobSeekerEducationalInstitutionQueryVariables
>
export const JobSeekerShowFeatureTopicDocument = gql`
    query ShowFeatureTopic($slug: String!) {
  featureTopicBySlug(slug: $slug) {
    id
    title
    description
    jobs {
      id
      title
      descriptionText
      coverImage
      liked
      accepted
      company {
        name
      }
      characteristicLabels {
        id
        name
      }
    }
  }
}
    `

/**
 * __useJobSeekerShowFeatureTopicQuery__
 *
 * To run a query within a React component, call `useJobSeekerShowFeatureTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerShowFeatureTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerShowFeatureTopicQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useJobSeekerShowFeatureTopicQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobSeekerShowFeatureTopicQuery,
    JobSeekerShowFeatureTopicQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerShowFeatureTopicQuery, JobSeekerShowFeatureTopicQueryVariables>(
    JobSeekerShowFeatureTopicDocument,
    options,
  )
}
export function useJobSeekerShowFeatureTopicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerShowFeatureTopicQuery,
    JobSeekerShowFeatureTopicQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<
    JobSeekerShowFeatureTopicQuery,
    JobSeekerShowFeatureTopicQueryVariables
  >(JobSeekerShowFeatureTopicDocument, options)
}
export type JobSeekerShowFeatureTopicQueryHookResult = ReturnType<
  typeof useJobSeekerShowFeatureTopicQuery
>
export type JobSeekerShowFeatureTopicLazyQueryHookResult = ReturnType<
  typeof useJobSeekerShowFeatureTopicLazyQuery
>
export type JobSeekerShowFeatureTopicQueryResult = Apollo.QueryResult<
  JobSeekerShowFeatureTopicQuery,
  JobSeekerShowFeatureTopicQueryVariables
>
export const JobSeekerListFeatureTopicsDocument = gql`
    query ListFeatureTopics {
  featureTopics {
    data {
      id
      title
      description
      slug
    }
  }
}
    `

/**
 * __useJobSeekerListFeatureTopicsQuery__
 *
 * To run a query within a React component, call `useJobSeekerListFeatureTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerListFeatureTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerListFeatureTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSeekerListFeatureTopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerListFeatureTopicsQuery,
    JobSeekerListFeatureTopicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerListFeatureTopicsQuery, JobSeekerListFeatureTopicsQueryVariables>(
    JobSeekerListFeatureTopicsDocument,
    options,
  )
}
export function useJobSeekerListFeatureTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerListFeatureTopicsQuery,
    JobSeekerListFeatureTopicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<
    JobSeekerListFeatureTopicsQuery,
    JobSeekerListFeatureTopicsQueryVariables
  >(JobSeekerListFeatureTopicsDocument, options)
}
export type JobSeekerListFeatureTopicsQueryHookResult = ReturnType<
  typeof useJobSeekerListFeatureTopicsQuery
>
export type JobSeekerListFeatureTopicsLazyQueryHookResult = ReturnType<
  typeof useJobSeekerListFeatureTopicsLazyQuery
>
export type JobSeekerListFeatureTopicsQueryResult = Apollo.QueryResult<
  JobSeekerListFeatureTopicsQuery,
  JobSeekerListFeatureTopicsQueryVariables
>
export const JobSeekerListFeaturedJobsDocument = gql`
    query ListFeaturedJobs($pagination: PaginationInput, $filters: [FilterInput!], $sort: SortInput) {
  featuredJobs(pagination: $pagination, filters: $filters, sort: $sort) {
    data {
      id
      job {
        id
        title
        descriptionText
        coverImage
        liked
        accepted
        kind
        targetGrades
        company {
          name
        }
        locations
        characteristicLabels {
          id
          name
        }
      }
    }
    pagination {
      total
    }
  }
}
    `

/**
 * __useJobSeekerListFeaturedJobsQuery__
 *
 * To run a query within a React component, call `useJobSeekerListFeaturedJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerListFeaturedJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerListFeaturedJobsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useJobSeekerListFeaturedJobsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerListFeaturedJobsQuery,
    JobSeekerListFeaturedJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerListFeaturedJobsQuery, JobSeekerListFeaturedJobsQueryVariables>(
    JobSeekerListFeaturedJobsDocument,
    options,
  )
}
export function useJobSeekerListFeaturedJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerListFeaturedJobsQuery,
    JobSeekerListFeaturedJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<
    JobSeekerListFeaturedJobsQuery,
    JobSeekerListFeaturedJobsQueryVariables
  >(JobSeekerListFeaturedJobsDocument, options)
}
export type JobSeekerListFeaturedJobsQueryHookResult = ReturnType<
  typeof useJobSeekerListFeaturedJobsQuery
>
export type JobSeekerListFeaturedJobsLazyQueryHookResult = ReturnType<
  typeof useJobSeekerListFeaturedJobsLazyQuery
>
export type JobSeekerListFeaturedJobsQueryResult = Apollo.QueryResult<
  JobSeekerListFeaturedJobsQuery,
  JobSeekerListFeaturedJobsQueryVariables
>
export const JobSeekerShowJobDocument = gql`
    query ShowJob($id: ID!) {
  job(id: $id) {
    ...CommonJob
    liked
    accepted
    characteristicLabels {
      id
      name
    }
    company {
      members {
        id
        name
        jobTitle
        bio
        avatar
      }
    }
  }
}
    ${JobSeekerCommonJobFragmentDoc}
${JobSeekerCommonCompanyFragmentDoc}`

/**
 * __useJobSeekerShowJobQuery__
 *
 * To run a query within a React component, call `useJobSeekerShowJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerShowJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerShowJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobSeekerShowJobQuery(
  baseOptions: Apollo.QueryHookOptions<JobSeekerShowJobQuery, JobSeekerShowJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerShowJobQuery, JobSeekerShowJobQueryVariables>(
    JobSeekerShowJobDocument,
    options,
  )
}
export function useJobSeekerShowJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobSeekerShowJobQuery, JobSeekerShowJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerShowJobQuery, JobSeekerShowJobQueryVariables>(
    JobSeekerShowJobDocument,
    options,
  )
}
export type JobSeekerShowJobQueryHookResult = ReturnType<typeof useJobSeekerShowJobQuery>
export type JobSeekerShowJobLazyQueryHookResult = ReturnType<typeof useJobSeekerShowJobLazyQuery>
export type JobSeekerShowJobQueryResult = Apollo.QueryResult<
  JobSeekerShowJobQuery,
  JobSeekerShowJobQueryVariables
>
export const JobSeekerListJobsDocument = gql`
    query ListJobs($pagination: PaginationInput) {
  jobs(pagination: $pagination) {
    data {
      ...CommonJob
    }
    pagination {
      total
    }
  }
}
    ${JobSeekerCommonJobFragmentDoc}
${JobSeekerCommonCompanyFragmentDoc}`

/**
 * __useJobSeekerListJobsQuery__
 *
 * To run a query within a React component, call `useJobSeekerListJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerListJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerListJobsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useJobSeekerListJobsQuery(
  baseOptions?: Apollo.QueryHookOptions<JobSeekerListJobsQuery, JobSeekerListJobsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerListJobsQuery, JobSeekerListJobsQueryVariables>(
    JobSeekerListJobsDocument,
    options,
  )
}
export function useJobSeekerListJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerListJobsQuery,
    JobSeekerListJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerListJobsQuery, JobSeekerListJobsQueryVariables>(
    JobSeekerListJobsDocument,
    options,
  )
}
export type JobSeekerListJobsQueryHookResult = ReturnType<typeof useJobSeekerListJobsQuery>
export type JobSeekerListJobsLazyQueryHookResult = ReturnType<typeof useJobSeekerListJobsLazyQuery>
export type JobSeekerListJobsQueryResult = Apollo.QueryResult<
  JobSeekerListJobsQuery,
  JobSeekerListJobsQueryVariables
>
export const JobSeekerJobLikesDocument = gql`
    query JobLikes($sort: SortInput) {
  jobLikes(sort: $sort) {
    data {
      id
      accepted
      direction
      job {
        id
        title
        company {
          name
        }
      }
    }
  }
}
    `

/**
 * __useJobSeekerJobLikesQuery__
 *
 * To run a query within a React component, call `useJobSeekerJobLikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerJobLikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerJobLikesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useJobSeekerJobLikesQuery(
  baseOptions?: Apollo.QueryHookOptions<JobSeekerJobLikesQuery, JobSeekerJobLikesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerJobLikesQuery, JobSeekerJobLikesQueryVariables>(
    JobSeekerJobLikesDocument,
    options,
  )
}
export function useJobSeekerJobLikesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerJobLikesQuery,
    JobSeekerJobLikesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerJobLikesQuery, JobSeekerJobLikesQueryVariables>(
    JobSeekerJobLikesDocument,
    options,
  )
}
export type JobSeekerJobLikesQueryHookResult = ReturnType<typeof useJobSeekerJobLikesQuery>
export type JobSeekerJobLikesLazyQueryHookResult = ReturnType<typeof useJobSeekerJobLikesLazyQuery>
export type JobSeekerJobLikesQueryResult = Apollo.QueryResult<
  JobSeekerJobLikesQuery,
  JobSeekerJobLikesQueryVariables
>
export const JobSeekerShowCompanyDocument = gql`
    query ShowCompany($id: ID!) {
  company(id: $id) {
    ...CommonCompany
    jobs {
      ...CommonJob
    }
  }
}
    ${JobSeekerCommonCompanyFragmentDoc}
${JobSeekerCommonJobFragmentDoc}`

/**
 * __useJobSeekerShowCompanyQuery__
 *
 * To run a query within a React component, call `useJobSeekerShowCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerShowCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerShowCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobSeekerShowCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobSeekerShowCompanyQuery,
    JobSeekerShowCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerShowCompanyQuery, JobSeekerShowCompanyQueryVariables>(
    JobSeekerShowCompanyDocument,
    options,
  )
}
export function useJobSeekerShowCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerShowCompanyQuery,
    JobSeekerShowCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerShowCompanyQuery, JobSeekerShowCompanyQueryVariables>(
    JobSeekerShowCompanyDocument,
    options,
  )
}
export type JobSeekerShowCompanyQueryHookResult = ReturnType<typeof useJobSeekerShowCompanyQuery>
export type JobSeekerShowCompanyLazyQueryHookResult = ReturnType<
  typeof useJobSeekerShowCompanyLazyQuery
>
export type JobSeekerShowCompanyQueryResult = Apollo.QueryResult<
  JobSeekerShowCompanyQuery,
  JobSeekerShowCompanyQueryVariables
>
export const JobSeekerCreateJobLikeDocument = gql`
    mutation CreateJobLike($input: CreateJobLikeInput!) {
  createJobLike(input: $input) {
    liked
    accepted
  }
}
    `
export type JobSeekerCreateJobLikeMutationFn = Apollo.MutationFunction<
  JobSeekerCreateJobLikeMutation,
  JobSeekerCreateJobLikeMutationVariables
>

/**
 * __useJobSeekerCreateJobLikeMutation__
 *
 * To run a mutation, you first call `useJobSeekerCreateJobLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerCreateJobLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerCreateJobLikeMutation, { data, loading, error }] = useJobSeekerCreateJobLikeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobSeekerCreateJobLikeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerCreateJobLikeMutation,
    JobSeekerCreateJobLikeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerCreateJobLikeMutation,
    JobSeekerCreateJobLikeMutationVariables
  >(JobSeekerCreateJobLikeDocument, options)
}
export type JobSeekerCreateJobLikeMutationHookResult = ReturnType<
  typeof useJobSeekerCreateJobLikeMutation
>
export type JobSeekerCreateJobLikeMutationResult =
  Apollo.MutationResult<JobSeekerCreateJobLikeMutation>
export type JobSeekerCreateJobLikeMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerCreateJobLikeMutation,
  JobSeekerCreateJobLikeMutationVariables
>
export const JobSeekerMarkJobLikesAsViewedByJobSeekerDocument = gql`
    mutation MarkJobLikesAsViewedByJobSeeker($ids: [ID!]!) {
  markJobLikesAsViewedByJobSeeker(ids: $ids) {
    success
  }
}
    `
export type JobSeekerMarkJobLikesAsViewedByJobSeekerMutationFn = Apollo.MutationFunction<
  JobSeekerMarkJobLikesAsViewedByJobSeekerMutation,
  JobSeekerMarkJobLikesAsViewedByJobSeekerMutationVariables
>

/**
 * __useJobSeekerMarkJobLikesAsViewedByJobSeekerMutation__
 *
 * To run a mutation, you first call `useJobSeekerMarkJobLikesAsViewedByJobSeekerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerMarkJobLikesAsViewedByJobSeekerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerMarkJobLikesAsViewedByJobSeekerMutation, { data, loading, error }] = useJobSeekerMarkJobLikesAsViewedByJobSeekerMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useJobSeekerMarkJobLikesAsViewedByJobSeekerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerMarkJobLikesAsViewedByJobSeekerMutation,
    JobSeekerMarkJobLikesAsViewedByJobSeekerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerMarkJobLikesAsViewedByJobSeekerMutation,
    JobSeekerMarkJobLikesAsViewedByJobSeekerMutationVariables
  >(JobSeekerMarkJobLikesAsViewedByJobSeekerDocument, options)
}
export type JobSeekerMarkJobLikesAsViewedByJobSeekerMutationHookResult = ReturnType<
  typeof useJobSeekerMarkJobLikesAsViewedByJobSeekerMutation
>
export type JobSeekerMarkJobLikesAsViewedByJobSeekerMutationResult =
  Apollo.MutationResult<JobSeekerMarkJobLikesAsViewedByJobSeekerMutation>
export type JobSeekerMarkJobLikesAsViewedByJobSeekerMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerMarkJobLikesAsViewedByJobSeekerMutation,
  JobSeekerMarkJobLikesAsViewedByJobSeekerMutationVariables
>
export const JobSeekerCreateJobInterviewDocument = gql`
    mutation createJobInterview($input: CreateJobInterviewInput!) {
  createJobInterview(input: $input) {
    id
    chatGptThreadId
  }
}
    `
export type JobSeekerCreateJobInterviewMutationFn = Apollo.MutationFunction<
  JobSeekerCreateJobInterviewMutation,
  JobSeekerCreateJobInterviewMutationVariables
>

/**
 * __useJobSeekerCreateJobInterviewMutation__
 *
 * To run a mutation, you first call `useJobSeekerCreateJobInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerCreateJobInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerCreateJobInterviewMutation, { data, loading, error }] = useJobSeekerCreateJobInterviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobSeekerCreateJobInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerCreateJobInterviewMutation,
    JobSeekerCreateJobInterviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerCreateJobInterviewMutation,
    JobSeekerCreateJobInterviewMutationVariables
  >(JobSeekerCreateJobInterviewDocument, options)
}
export type JobSeekerCreateJobInterviewMutationHookResult = ReturnType<
  typeof useJobSeekerCreateJobInterviewMutation
>
export type JobSeekerCreateJobInterviewMutationResult =
  Apollo.MutationResult<JobSeekerCreateJobInterviewMutation>
export type JobSeekerCreateJobInterviewMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerCreateJobInterviewMutation,
  JobSeekerCreateJobInterviewMutationVariables
>
export const JobSeekerJobInterviewsAllDocument = gql`
    query jobInterviewsAll {
  jobInterviewsAll {
    id
    job {
      company {
        name
      }
    }
    chatGptThread {
      id
      kind
      createdAt
    }
  }
}
    `

/**
 * __useJobSeekerJobInterviewsAllQuery__
 *
 * To run a query within a React component, call `useJobSeekerJobInterviewsAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerJobInterviewsAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerJobInterviewsAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSeekerJobInterviewsAllQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerJobInterviewsAllQuery,
    JobSeekerJobInterviewsAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerJobInterviewsAllQuery, JobSeekerJobInterviewsAllQueryVariables>(
    JobSeekerJobInterviewsAllDocument,
    options,
  )
}
export function useJobSeekerJobInterviewsAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerJobInterviewsAllQuery,
    JobSeekerJobInterviewsAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<
    JobSeekerJobInterviewsAllQuery,
    JobSeekerJobInterviewsAllQueryVariables
  >(JobSeekerJobInterviewsAllDocument, options)
}
export type JobSeekerJobInterviewsAllQueryHookResult = ReturnType<
  typeof useJobSeekerJobInterviewsAllQuery
>
export type JobSeekerJobInterviewsAllLazyQueryHookResult = ReturnType<
  typeof useJobSeekerJobInterviewsAllLazyQuery
>
export type JobSeekerJobInterviewsAllQueryResult = Apollo.QueryResult<
  JobSeekerJobInterviewsAllQuery,
  JobSeekerJobInterviewsAllQueryVariables
>
export const JobSeekerApplyJobInterviewDocument = gql`
    mutation applyJobInterview($input: ApplyJobInterviewInput!) {
  applyJobInterview(input: $input) {
    success
  }
}
    `
export type JobSeekerApplyJobInterviewMutationFn = Apollo.MutationFunction<
  JobSeekerApplyJobInterviewMutation,
  JobSeekerApplyJobInterviewMutationVariables
>

/**
 * __useJobSeekerApplyJobInterviewMutation__
 *
 * To run a mutation, you first call `useJobSeekerApplyJobInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerApplyJobInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerApplyJobInterviewMutation, { data, loading, error }] = useJobSeekerApplyJobInterviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobSeekerApplyJobInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerApplyJobInterviewMutation,
    JobSeekerApplyJobInterviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerApplyJobInterviewMutation,
    JobSeekerApplyJobInterviewMutationVariables
  >(JobSeekerApplyJobInterviewDocument, options)
}
export type JobSeekerApplyJobInterviewMutationHookResult = ReturnType<
  typeof useJobSeekerApplyJobInterviewMutation
>
export type JobSeekerApplyJobInterviewMutationResult =
  Apollo.MutationResult<JobSeekerApplyJobInterviewMutation>
export type JobSeekerApplyJobInterviewMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerApplyJobInterviewMutation,
  JobSeekerApplyJobInterviewMutationVariables
>
export const JobSeekerMySocialAccountsDocument = gql`
    query MySocialAccounts {
  jobSeekerMe {
    id
    isInternal
    hasLinkedLineAccount
    isFollowingLineAccount
    socialAccounts {
      provider
      profileUrl
    }
  }
}
    `

/**
 * __useJobSeekerMySocialAccountsQuery__
 *
 * To run a query within a React component, call `useJobSeekerMySocialAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerMySocialAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerMySocialAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSeekerMySocialAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerMySocialAccountsQuery,
    JobSeekerMySocialAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerMySocialAccountsQuery, JobSeekerMySocialAccountsQueryVariables>(
    JobSeekerMySocialAccountsDocument,
    options,
  )
}
export function useJobSeekerMySocialAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerMySocialAccountsQuery,
    JobSeekerMySocialAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<
    JobSeekerMySocialAccountsQuery,
    JobSeekerMySocialAccountsQueryVariables
  >(JobSeekerMySocialAccountsDocument, options)
}
export type JobSeekerMySocialAccountsQueryHookResult = ReturnType<
  typeof useJobSeekerMySocialAccountsQuery
>
export type JobSeekerMySocialAccountsLazyQueryHookResult = ReturnType<
  typeof useJobSeekerMySocialAccountsLazyQuery
>
export type JobSeekerMySocialAccountsQueryResult = Apollo.QueryResult<
  JobSeekerMySocialAccountsQuery,
  JobSeekerMySocialAccountsQueryVariables
>
export const JobSeekerMyAchievementsDocument = gql`
    query MyAchievements {
  jobSeekerMe {
    id
    achievements {
      id
      title
      url
      description
      date
      attachment
    }
  }
}
    `

/**
 * __useJobSeekerMyAchievementsQuery__
 *
 * To run a query within a React component, call `useJobSeekerMyAchievementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerMyAchievementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerMyAchievementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSeekerMyAchievementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerMyAchievementsQuery,
    JobSeekerMyAchievementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerMyAchievementsQuery, JobSeekerMyAchievementsQueryVariables>(
    JobSeekerMyAchievementsDocument,
    options,
  )
}
export function useJobSeekerMyAchievementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerMyAchievementsQuery,
    JobSeekerMyAchievementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerMyAchievementsQuery, JobSeekerMyAchievementsQueryVariables>(
    JobSeekerMyAchievementsDocument,
    options,
  )
}
export type JobSeekerMyAchievementsQueryHookResult = ReturnType<
  typeof useJobSeekerMyAchievementsQuery
>
export type JobSeekerMyAchievementsLazyQueryHookResult = ReturnType<
  typeof useJobSeekerMyAchievementsLazyQuery
>
export type JobSeekerMyAchievementsQueryResult = Apollo.QueryResult<
  JobSeekerMyAchievementsQuery,
  JobSeekerMyAchievementsQueryVariables
>
export const JobSeekerMeDocument = gql`
    query JobSeekerMe {
  jobSeekerMe {
    introducedByName
    isInternal
    slug
    bio
    ...CommonJobSeeker
  }
}
    ${JobSeekerCommonJobSeekerFragmentDoc}`

/**
 * __useJobSeekerMeQuery__
 *
 * To run a query within a React component, call `useJobSeekerMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSeekerMeQuery(
  baseOptions?: Apollo.QueryHookOptions<JobSeekerMeQuery, JobSeekerMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerMeQuery, JobSeekerMeQueryVariables>(JobSeekerMeDocument, options)
}
export function useJobSeekerMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobSeekerMeQuery, JobSeekerMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerMeQuery, JobSeekerMeQueryVariables>(
    JobSeekerMeDocument,
    options,
  )
}
export type JobSeekerMeQueryHookResult = ReturnType<typeof useJobSeekerMeQuery>
export type JobSeekerMeLazyQueryHookResult = ReturnType<typeof useJobSeekerMeLazyQuery>
export type JobSeekerMeQueryResult = Apollo.QueryResult<JobSeekerMeQuery, JobSeekerMeQueryVariables>
export const JobSeekerMeForRikelinkDocument = gql`
    query JobSeekerMeForRikelink {
  jobSeekerMe {
    id
    bio
    slug
    emailForProfile
    firstName
    lastName
    image
    address
    placeOfBirth
    status
    birthday
    desiredJobTypes
    desiredWorkLocations
    desiredCompanySizes
    careerInterests
    studyKeywords
    studyDetail
    certifications
    focusSummary
    focusDetail
    thingsChangedAfterCovid
    toeicScore
    socialAccounts {
      provider
      profileUrl
    }
    educationHistory {
      id
      name
      course
      faculty
      department
      grade
      graduatedOn
      major
      researchDetail
      researchSubject
      highSchool
    }
    achievements {
      id
      title
      url
      description
      date
      attachment
    }
  }
}
    `

/**
 * __useJobSeekerMeForRikelinkQuery__
 *
 * To run a query within a React component, call `useJobSeekerMeForRikelinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerMeForRikelinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerMeForRikelinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSeekerMeForRikelinkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerMeForRikelinkQuery,
    JobSeekerMeForRikelinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerMeForRikelinkQuery, JobSeekerMeForRikelinkQueryVariables>(
    JobSeekerMeForRikelinkDocument,
    options,
  )
}
export function useJobSeekerMeForRikelinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerMeForRikelinkQuery,
    JobSeekerMeForRikelinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerMeForRikelinkQuery, JobSeekerMeForRikelinkQueryVariables>(
    JobSeekerMeForRikelinkDocument,
    options,
  )
}
export type JobSeekerMeForRikelinkQueryHookResult = ReturnType<
  typeof useJobSeekerMeForRikelinkQuery
>
export type JobSeekerMeForRikelinkLazyQueryHookResult = ReturnType<
  typeof useJobSeekerMeForRikelinkLazyQuery
>
export type JobSeekerMeForRikelinkQueryResult = Apollo.QueryResult<
  JobSeekerMeForRikelinkQuery,
  JobSeekerMeForRikelinkQueryVariables
>
export const JobSeekerBySlugDocument = gql`
    query JobSeekerBySlug($slug: String!) {
  jobSeekerBySlug(slug: $slug) {
    id
    bio
    slug
    emailForProfile
    firstName
    lastName
    image
    address
    placeOfBirth
    status
    birthday
    desiredJobTypes
    desiredWorkLocations
    desiredCompanySizes
    careerInterests
    studyKeywords
    studyDetail
    certifications
    focusSummary
    focusDetail
    thingsChangedAfterCovid
    toeicScore
    socialAccounts {
      provider
      profileUrl
    }
    educationHistory {
      id
      name
      course
      faculty
      department
      grade
      graduatedOn
      major
      researchDetail
      researchSubject
      highSchool
    }
    achievements {
      id
      title
      url
      description
      date
      attachment
    }
  }
}
    `

/**
 * __useJobSeekerBySlugQuery__
 *
 * To run a query within a React component, call `useJobSeekerBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useJobSeekerBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<JobSeekerBySlugQuery, JobSeekerBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerBySlugQuery, JobSeekerBySlugQueryVariables>(
    JobSeekerBySlugDocument,
    options,
  )
}
export function useJobSeekerBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobSeekerBySlugQuery, JobSeekerBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerBySlugQuery, JobSeekerBySlugQueryVariables>(
    JobSeekerBySlugDocument,
    options,
  )
}
export type JobSeekerBySlugQueryHookResult = ReturnType<typeof useJobSeekerBySlugQuery>
export type JobSeekerBySlugLazyQueryHookResult = ReturnType<typeof useJobSeekerBySlugLazyQuery>
export type JobSeekerBySlugQueryResult = Apollo.QueryResult<
  JobSeekerBySlugQuery,
  JobSeekerBySlugQueryVariables
>
export const JobSeekerBySlugForReferralCommentsDocument = gql`
    query JobSeekerBySlugForReferralComments($slug: String!) {
  jobSeekerBySlug(slug: $slug) {
    id
    image
    firstName
    lastName
    educationHistory {
      name
      course
      faculty
      department
      grade
      major
    }
  }
}
    `

/**
 * __useJobSeekerBySlugForReferralCommentsQuery__
 *
 * To run a query within a React component, call `useJobSeekerBySlugForReferralCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerBySlugForReferralCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerBySlugForReferralCommentsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useJobSeekerBySlugForReferralCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobSeekerBySlugForReferralCommentsQuery,
    JobSeekerBySlugForReferralCommentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<
    JobSeekerBySlugForReferralCommentsQuery,
    JobSeekerBySlugForReferralCommentsQueryVariables
  >(JobSeekerBySlugForReferralCommentsDocument, options)
}
export function useJobSeekerBySlugForReferralCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerBySlugForReferralCommentsQuery,
    JobSeekerBySlugForReferralCommentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<
    JobSeekerBySlugForReferralCommentsQuery,
    JobSeekerBySlugForReferralCommentsQueryVariables
  >(JobSeekerBySlugForReferralCommentsDocument, options)
}
export type JobSeekerBySlugForReferralCommentsQueryHookResult = ReturnType<
  typeof useJobSeekerBySlugForReferralCommentsQuery
>
export type JobSeekerBySlugForReferralCommentsLazyQueryHookResult = ReturnType<
  typeof useJobSeekerBySlugForReferralCommentsLazyQuery
>
export type JobSeekerBySlugForReferralCommentsQueryResult = Apollo.QueryResult<
  JobSeekerBySlugForReferralCommentsQuery,
  JobSeekerBySlugForReferralCommentsQueryVariables
>
export const JobSeekerUpdateProfileDocument = gql`
    mutation UpdateProfile($input: UpdateJobSeekerInput!) {
  updateJobSeeker(input: $input) {
    ...CommonJobSeeker
  }
}
    ${JobSeekerCommonJobSeekerFragmentDoc}`
export type JobSeekerUpdateProfileMutationFn = Apollo.MutationFunction<
  JobSeekerUpdateProfileMutation,
  JobSeekerUpdateProfileMutationVariables
>

/**
 * __useJobSeekerUpdateProfileMutation__
 *
 * To run a mutation, you first call `useJobSeekerUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerUpdateProfileMutation, { data, loading, error }] = useJobSeekerUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobSeekerUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerUpdateProfileMutation,
    JobSeekerUpdateProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerUpdateProfileMutation,
    JobSeekerUpdateProfileMutationVariables
  >(JobSeekerUpdateProfileDocument, options)
}
export type JobSeekerUpdateProfileMutationHookResult = ReturnType<
  typeof useJobSeekerUpdateProfileMutation
>
export type JobSeekerUpdateProfileMutationResult =
  Apollo.MutationResult<JobSeekerUpdateProfileMutation>
export type JobSeekerUpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerUpdateProfileMutation,
  JobSeekerUpdateProfileMutationVariables
>
export const JobSeekerAssociateSocialAccountDocument = gql`
    mutation associateSocialAccount($input: AssociateSocialAccountInput!) {
  associateSocialAccount(input: $input) {
    provider
    profileUrl
  }
}
    `
export type JobSeekerAssociateSocialAccountMutationFn = Apollo.MutationFunction<
  JobSeekerAssociateSocialAccountMutation,
  JobSeekerAssociateSocialAccountMutationVariables
>

/**
 * __useJobSeekerAssociateSocialAccountMutation__
 *
 * To run a mutation, you first call `useJobSeekerAssociateSocialAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerAssociateSocialAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerAssociateSocialAccountMutation, { data, loading, error }] = useJobSeekerAssociateSocialAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobSeekerAssociateSocialAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerAssociateSocialAccountMutation,
    JobSeekerAssociateSocialAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerAssociateSocialAccountMutation,
    JobSeekerAssociateSocialAccountMutationVariables
  >(JobSeekerAssociateSocialAccountDocument, options)
}
export type JobSeekerAssociateSocialAccountMutationHookResult = ReturnType<
  typeof useJobSeekerAssociateSocialAccountMutation
>
export type JobSeekerAssociateSocialAccountMutationResult =
  Apollo.MutationResult<JobSeekerAssociateSocialAccountMutation>
export type JobSeekerAssociateSocialAccountMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerAssociateSocialAccountMutation,
  JobSeekerAssociateSocialAccountMutationVariables
>
export const JobSeekerDisassociateSocialAccountDocument = gql`
    mutation disassociateSocialAccount($provider: SocialAccountProvider!) {
  disassociateSocialAccount(provider: $provider) {
    success
  }
}
    `
export type JobSeekerDisassociateSocialAccountMutationFn = Apollo.MutationFunction<
  JobSeekerDisassociateSocialAccountMutation,
  JobSeekerDisassociateSocialAccountMutationVariables
>

/**
 * __useJobSeekerDisassociateSocialAccountMutation__
 *
 * To run a mutation, you first call `useJobSeekerDisassociateSocialAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerDisassociateSocialAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerDisassociateSocialAccountMutation, { data, loading, error }] = useJobSeekerDisassociateSocialAccountMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useJobSeekerDisassociateSocialAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerDisassociateSocialAccountMutation,
    JobSeekerDisassociateSocialAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerDisassociateSocialAccountMutation,
    JobSeekerDisassociateSocialAccountMutationVariables
  >(JobSeekerDisassociateSocialAccountDocument, options)
}
export type JobSeekerDisassociateSocialAccountMutationHookResult = ReturnType<
  typeof useJobSeekerDisassociateSocialAccountMutation
>
export type JobSeekerDisassociateSocialAccountMutationResult =
  Apollo.MutationResult<JobSeekerDisassociateSocialAccountMutation>
export type JobSeekerDisassociateSocialAccountMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerDisassociateSocialAccountMutation,
  JobSeekerDisassociateSocialAccountMutationVariables
>
export const JobSeekerCreateAchievementDocument = gql`
    mutation createAchievement($input: AchievementInput!) {
  createAchievement(input: $input) {
    id
    title
    url
    description
    date
    attachment
  }
}
    `
export type JobSeekerCreateAchievementMutationFn = Apollo.MutationFunction<
  JobSeekerCreateAchievementMutation,
  JobSeekerCreateAchievementMutationVariables
>

/**
 * __useJobSeekerCreateAchievementMutation__
 *
 * To run a mutation, you first call `useJobSeekerCreateAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerCreateAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerCreateAchievementMutation, { data, loading, error }] = useJobSeekerCreateAchievementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobSeekerCreateAchievementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerCreateAchievementMutation,
    JobSeekerCreateAchievementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerCreateAchievementMutation,
    JobSeekerCreateAchievementMutationVariables
  >(JobSeekerCreateAchievementDocument, options)
}
export type JobSeekerCreateAchievementMutationHookResult = ReturnType<
  typeof useJobSeekerCreateAchievementMutation
>
export type JobSeekerCreateAchievementMutationResult =
  Apollo.MutationResult<JobSeekerCreateAchievementMutation>
export type JobSeekerCreateAchievementMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerCreateAchievementMutation,
  JobSeekerCreateAchievementMutationVariables
>
export const JobSeekerUpdateAchievementDocument = gql`
    mutation updateAchievement($id: String!, $input: AchievementInput!) {
  updateAchievement(id: $id, input: $input) {
    id
    title
    url
    description
    date
    attachment
  }
}
    `
export type JobSeekerUpdateAchievementMutationFn = Apollo.MutationFunction<
  JobSeekerUpdateAchievementMutation,
  JobSeekerUpdateAchievementMutationVariables
>

/**
 * __useJobSeekerUpdateAchievementMutation__
 *
 * To run a mutation, you first call `useJobSeekerUpdateAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerUpdateAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerUpdateAchievementMutation, { data, loading, error }] = useJobSeekerUpdateAchievementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobSeekerUpdateAchievementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerUpdateAchievementMutation,
    JobSeekerUpdateAchievementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerUpdateAchievementMutation,
    JobSeekerUpdateAchievementMutationVariables
  >(JobSeekerUpdateAchievementDocument, options)
}
export type JobSeekerUpdateAchievementMutationHookResult = ReturnType<
  typeof useJobSeekerUpdateAchievementMutation
>
export type JobSeekerUpdateAchievementMutationResult =
  Apollo.MutationResult<JobSeekerUpdateAchievementMutation>
export type JobSeekerUpdateAchievementMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerUpdateAchievementMutation,
  JobSeekerUpdateAchievementMutationVariables
>
export const JobSeekerDeleteAchievementDocument = gql`
    mutation deleteAchievement($id: String!) {
  deleteAchievement(id: $id) {
    success
  }
}
    `
export type JobSeekerDeleteAchievementMutationFn = Apollo.MutationFunction<
  JobSeekerDeleteAchievementMutation,
  JobSeekerDeleteAchievementMutationVariables
>

/**
 * __useJobSeekerDeleteAchievementMutation__
 *
 * To run a mutation, you first call `useJobSeekerDeleteAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerDeleteAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerDeleteAchievementMutation, { data, loading, error }] = useJobSeekerDeleteAchievementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobSeekerDeleteAchievementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerDeleteAchievementMutation,
    JobSeekerDeleteAchievementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerDeleteAchievementMutation,
    JobSeekerDeleteAchievementMutationVariables
  >(JobSeekerDeleteAchievementDocument, options)
}
export type JobSeekerDeleteAchievementMutationHookResult = ReturnType<
  typeof useJobSeekerDeleteAchievementMutation
>
export type JobSeekerDeleteAchievementMutationResult =
  Apollo.MutationResult<JobSeekerDeleteAchievementMutation>
export type JobSeekerDeleteAchievementMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerDeleteAchievementMutation,
  JobSeekerDeleteAchievementMutationVariables
>
export const JobSeekerUnreadItemsCountDocument = gql`
    query unreadItemsCount {
  unreadItemsCount {
    jobLikes
    sentScouts
    messages
  }
}
    `

/**
 * __useJobSeekerUnreadItemsCountQuery__
 *
 * To run a query within a React component, call `useJobSeekerUnreadItemsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerUnreadItemsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerUnreadItemsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSeekerUnreadItemsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerUnreadItemsCountQuery,
    JobSeekerUnreadItemsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerUnreadItemsCountQuery, JobSeekerUnreadItemsCountQueryVariables>(
    JobSeekerUnreadItemsCountDocument,
    options,
  )
}
export function useJobSeekerUnreadItemsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerUnreadItemsCountQuery,
    JobSeekerUnreadItemsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<
    JobSeekerUnreadItemsCountQuery,
    JobSeekerUnreadItemsCountQueryVariables
  >(JobSeekerUnreadItemsCountDocument, options)
}
export type JobSeekerUnreadItemsCountQueryHookResult = ReturnType<
  typeof useJobSeekerUnreadItemsCountQuery
>
export type JobSeekerUnreadItemsCountLazyQueryHookResult = ReturnType<
  typeof useJobSeekerUnreadItemsCountLazyQuery
>
export type JobSeekerUnreadItemsCountQueryResult = Apollo.QueryResult<
  JobSeekerUnreadItemsCountQuery,
  JobSeekerUnreadItemsCountQueryVariables
>
export const JobSeekerShowJobSeekerPrivacySettingDocument = gql`
    query ShowJobSeekerPrivacySetting {
  jobSeekerPrivacySetting {
    allowEmailPublication
    allowProfilePublication
    allowProfileAccessByCompanies
  }
}
    `

/**
 * __useJobSeekerShowJobSeekerPrivacySettingQuery__
 *
 * To run a query within a React component, call `useJobSeekerShowJobSeekerPrivacySettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerShowJobSeekerPrivacySettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerShowJobSeekerPrivacySettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSeekerShowJobSeekerPrivacySettingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerShowJobSeekerPrivacySettingQuery,
    JobSeekerShowJobSeekerPrivacySettingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<
    JobSeekerShowJobSeekerPrivacySettingQuery,
    JobSeekerShowJobSeekerPrivacySettingQueryVariables
  >(JobSeekerShowJobSeekerPrivacySettingDocument, options)
}
export function useJobSeekerShowJobSeekerPrivacySettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerShowJobSeekerPrivacySettingQuery,
    JobSeekerShowJobSeekerPrivacySettingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<
    JobSeekerShowJobSeekerPrivacySettingQuery,
    JobSeekerShowJobSeekerPrivacySettingQueryVariables
  >(JobSeekerShowJobSeekerPrivacySettingDocument, options)
}
export type JobSeekerShowJobSeekerPrivacySettingQueryHookResult = ReturnType<
  typeof useJobSeekerShowJobSeekerPrivacySettingQuery
>
export type JobSeekerShowJobSeekerPrivacySettingLazyQueryHookResult = ReturnType<
  typeof useJobSeekerShowJobSeekerPrivacySettingLazyQuery
>
export type JobSeekerShowJobSeekerPrivacySettingQueryResult = Apollo.QueryResult<
  JobSeekerShowJobSeekerPrivacySettingQuery,
  JobSeekerShowJobSeekerPrivacySettingQueryVariables
>
export const JobSeekerUpdateJobSeekerPrivacySettingDocument = gql`
    mutation UpdateJobSeekerPrivacySetting($input: UpdateJobSeekerPrivacySettingInput!) {
  updateJobSeekerPrivacySetting(input: $input) {
    allowEmailPublication
    allowProfilePublication
    allowProfileAccessByCompanies
  }
}
    `
export type JobSeekerUpdateJobSeekerPrivacySettingMutationFn = Apollo.MutationFunction<
  JobSeekerUpdateJobSeekerPrivacySettingMutation,
  JobSeekerUpdateJobSeekerPrivacySettingMutationVariables
>

/**
 * __useJobSeekerUpdateJobSeekerPrivacySettingMutation__
 *
 * To run a mutation, you first call `useJobSeekerUpdateJobSeekerPrivacySettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerUpdateJobSeekerPrivacySettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerUpdateJobSeekerPrivacySettingMutation, { data, loading, error }] = useJobSeekerUpdateJobSeekerPrivacySettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobSeekerUpdateJobSeekerPrivacySettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerUpdateJobSeekerPrivacySettingMutation,
    JobSeekerUpdateJobSeekerPrivacySettingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerUpdateJobSeekerPrivacySettingMutation,
    JobSeekerUpdateJobSeekerPrivacySettingMutationVariables
  >(JobSeekerUpdateJobSeekerPrivacySettingDocument, options)
}
export type JobSeekerUpdateJobSeekerPrivacySettingMutationHookResult = ReturnType<
  typeof useJobSeekerUpdateJobSeekerPrivacySettingMutation
>
export type JobSeekerUpdateJobSeekerPrivacySettingMutationResult =
  Apollo.MutationResult<JobSeekerUpdateJobSeekerPrivacySettingMutation>
export type JobSeekerUpdateJobSeekerPrivacySettingMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerUpdateJobSeekerPrivacySettingMutation,
  JobSeekerUpdateJobSeekerPrivacySettingMutationVariables
>
export const JobSeekerListMembersDocument = gql`
    query ListMembers {
  members {
    ...CommonMember
  }
}
    ${JobSeekerCommonMemberFragmentDoc}`

/**
 * __useJobSeekerListMembersQuery__
 *
 * To run a query within a React component, call `useJobSeekerListMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerListMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerListMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSeekerListMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerListMembersQuery,
    JobSeekerListMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerListMembersQuery, JobSeekerListMembersQueryVariables>(
    JobSeekerListMembersDocument,
    options,
  )
}
export function useJobSeekerListMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerListMembersQuery,
    JobSeekerListMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerListMembersQuery, JobSeekerListMembersQueryVariables>(
    JobSeekerListMembersDocument,
    options,
  )
}
export type JobSeekerListMembersQueryHookResult = ReturnType<typeof useJobSeekerListMembersQuery>
export type JobSeekerListMembersLazyQueryHookResult = ReturnType<
  typeof useJobSeekerListMembersLazyQuery
>
export type JobSeekerListMembersQueryResult = Apollo.QueryResult<
  JobSeekerListMembersQuery,
  JobSeekerListMembersQueryVariables
>
export const JobSeekerShowMemberDocument = gql`
    query ShowMember($id: ID!) {
  member(id: $id) {
    ...CommonMember
    company {
      name
    }
  }
}
    ${JobSeekerCommonMemberFragmentDoc}`

/**
 * __useJobSeekerShowMemberQuery__
 *
 * To run a query within a React component, call `useJobSeekerShowMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerShowMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerShowMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobSeekerShowMemberQuery(
  baseOptions: Apollo.QueryHookOptions<JobSeekerShowMemberQuery, JobSeekerShowMemberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerShowMemberQuery, JobSeekerShowMemberQueryVariables>(
    JobSeekerShowMemberDocument,
    options,
  )
}
export function useJobSeekerShowMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerShowMemberQuery,
    JobSeekerShowMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerShowMemberQuery, JobSeekerShowMemberQueryVariables>(
    JobSeekerShowMemberDocument,
    options,
  )
}
export type JobSeekerShowMemberQueryHookResult = ReturnType<typeof useJobSeekerShowMemberQuery>
export type JobSeekerShowMemberLazyQueryHookResult = ReturnType<
  typeof useJobSeekerShowMemberLazyQuery
>
export type JobSeekerShowMemberQueryResult = Apollo.QueryResult<
  JobSeekerShowMemberQuery,
  JobSeekerShowMemberQueryVariables
>
export const JobSeekerMessageThreadsDocument = gql`
    query MessageThreads {
  messageThreads {
    data {
      id
      job {
        id
      }
      canAcceptScout
      canPostMessage
      company {
        name
        logo
      }
      latestMessage {
        createdAt
        text
      }
      origin
    }
  }
}
    `

/**
 * __useJobSeekerMessageThreadsQuery__
 *
 * To run a query within a React component, call `useJobSeekerMessageThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerMessageThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerMessageThreadsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSeekerMessageThreadsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerMessageThreadsQuery,
    JobSeekerMessageThreadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerMessageThreadsQuery, JobSeekerMessageThreadsQueryVariables>(
    JobSeekerMessageThreadsDocument,
    options,
  )
}
export function useJobSeekerMessageThreadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerMessageThreadsQuery,
    JobSeekerMessageThreadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerMessageThreadsQuery, JobSeekerMessageThreadsQueryVariables>(
    JobSeekerMessageThreadsDocument,
    options,
  )
}
export type JobSeekerMessageThreadsQueryHookResult = ReturnType<
  typeof useJobSeekerMessageThreadsQuery
>
export type JobSeekerMessageThreadsLazyQueryHookResult = ReturnType<
  typeof useJobSeekerMessageThreadsLazyQuery
>
export type JobSeekerMessageThreadsQueryResult = Apollo.QueryResult<
  JobSeekerMessageThreadsQuery,
  JobSeekerMessageThreadsQueryVariables
>
export const JobSeekerMessageThreadDocument = gql`
    query MessageThread($id: ID!) {
  messageThread(id: $id) {
    origin
    isLike
    company {
      name
      logo
    }
    job {
      id
    }
    canAcceptScout
    canPostMessage
    messages {
      id
      text
      kind
      createdAt
      jobSeeker {
        id
        image
        firstName
        lastName
        fullName
      }
      companyStaff {
        id
        firstName
        lastName
        fullName
        company {
          name
        }
      }
    }
  }
}
    `

/**
 * __useJobSeekerMessageThreadQuery__
 *
 * To run a query within a React component, call `useJobSeekerMessageThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerMessageThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerMessageThreadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobSeekerMessageThreadQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobSeekerMessageThreadQuery,
    JobSeekerMessageThreadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerMessageThreadQuery, JobSeekerMessageThreadQueryVariables>(
    JobSeekerMessageThreadDocument,
    options,
  )
}
export function useJobSeekerMessageThreadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerMessageThreadQuery,
    JobSeekerMessageThreadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerMessageThreadQuery, JobSeekerMessageThreadQueryVariables>(
    JobSeekerMessageThreadDocument,
    options,
  )
}
export type JobSeekerMessageThreadQueryHookResult = ReturnType<
  typeof useJobSeekerMessageThreadQuery
>
export type JobSeekerMessageThreadLazyQueryHookResult = ReturnType<
  typeof useJobSeekerMessageThreadLazyQuery
>
export type JobSeekerMessageThreadQueryResult = Apollo.QueryResult<
  JobSeekerMessageThreadQuery,
  JobSeekerMessageThreadQueryVariables
>
export const JobSeekerPostMessageDocument = gql`
    mutation PostMessage($text: String!, $threadId: String!) {
  postMessage(text: $text, threadId: $threadId) {
    id
    messages {
      id
    }
  }
}
    `
export type JobSeekerPostMessageMutationFn = Apollo.MutationFunction<
  JobSeekerPostMessageMutation,
  JobSeekerPostMessageMutationVariables
>

/**
 * __useJobSeekerPostMessageMutation__
 *
 * To run a mutation, you first call `useJobSeekerPostMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerPostMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerPostMessageMutation, { data, loading, error }] = useJobSeekerPostMessageMutation({
 *   variables: {
 *      text: // value for 'text'
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useJobSeekerPostMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerPostMessageMutation,
    JobSeekerPostMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<JobSeekerPostMessageMutation, JobSeekerPostMessageMutationVariables>(
    JobSeekerPostMessageDocument,
    options,
  )
}
export type JobSeekerPostMessageMutationHookResult = ReturnType<
  typeof useJobSeekerPostMessageMutation
>
export type JobSeekerPostMessageMutationResult = Apollo.MutationResult<JobSeekerPostMessageMutation>
export type JobSeekerPostMessageMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerPostMessageMutation,
  JobSeekerPostMessageMutationVariables
>
export const JobSeekerAcceptScoutDocument = gql`
    mutation AcceptScout($threadId: String!) {
  acceptScout(threadId: $threadId) {
    id
  }
}
    `
export type JobSeekerAcceptScoutMutationFn = Apollo.MutationFunction<
  JobSeekerAcceptScoutMutation,
  JobSeekerAcceptScoutMutationVariables
>

/**
 * __useJobSeekerAcceptScoutMutation__
 *
 * To run a mutation, you first call `useJobSeekerAcceptScoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerAcceptScoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerAcceptScoutMutation, { data, loading, error }] = useJobSeekerAcceptScoutMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useJobSeekerAcceptScoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerAcceptScoutMutation,
    JobSeekerAcceptScoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<JobSeekerAcceptScoutMutation, JobSeekerAcceptScoutMutationVariables>(
    JobSeekerAcceptScoutDocument,
    options,
  )
}
export type JobSeekerAcceptScoutMutationHookResult = ReturnType<
  typeof useJobSeekerAcceptScoutMutation
>
export type JobSeekerAcceptScoutMutationResult = Apollo.MutationResult<JobSeekerAcceptScoutMutation>
export type JobSeekerAcceptScoutMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerAcceptScoutMutation,
  JobSeekerAcceptScoutMutationVariables
>
export const JobSeekerListProductsDocument = gql`
    query ListProducts {
  products {
    ...CommonProduct
  }
}
    ${JobSeekerCommonProductFragmentDoc}`

/**
 * __useJobSeekerListProductsQuery__
 *
 * To run a query within a React component, call `useJobSeekerListProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerListProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerListProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSeekerListProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSeekerListProductsQuery,
    JobSeekerListProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerListProductsQuery, JobSeekerListProductsQueryVariables>(
    JobSeekerListProductsDocument,
    options,
  )
}
export function useJobSeekerListProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerListProductsQuery,
    JobSeekerListProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerListProductsQuery, JobSeekerListProductsQueryVariables>(
    JobSeekerListProductsDocument,
    options,
  )
}
export type JobSeekerListProductsQueryHookResult = ReturnType<typeof useJobSeekerListProductsQuery>
export type JobSeekerListProductsLazyQueryHookResult = ReturnType<
  typeof useJobSeekerListProductsLazyQuery
>
export type JobSeekerListProductsQueryResult = Apollo.QueryResult<
  JobSeekerListProductsQuery,
  JobSeekerListProductsQueryVariables
>
export const JobSeekerShowProductDocument = gql`
    query ShowProduct($id: ID!) {
  product(id: $id) {
    ...CommonProduct
  }
}
    ${JobSeekerCommonProductFragmentDoc}`

/**
 * __useJobSeekerShowProductQuery__
 *
 * To run a query within a React component, call `useJobSeekerShowProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerShowProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerShowProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobSeekerShowProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobSeekerShowProductQuery,
    JobSeekerShowProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerShowProductQuery, JobSeekerShowProductQueryVariables>(
    JobSeekerShowProductDocument,
    options,
  )
}
export function useJobSeekerShowProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerShowProductQuery,
    JobSeekerShowProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<JobSeekerShowProductQuery, JobSeekerShowProductQueryVariables>(
    JobSeekerShowProductDocument,
    options,
  )
}
export type JobSeekerShowProductQueryHookResult = ReturnType<typeof useJobSeekerShowProductQuery>
export type JobSeekerShowProductLazyQueryHookResult = ReturnType<
  typeof useJobSeekerShowProductLazyQuery
>
export type JobSeekerShowProductQueryResult = Apollo.QueryResult<
  JobSeekerShowProductQuery,
  JobSeekerShowProductQueryVariables
>
export const JobSeekerCreateReferralCommentDocument = gql`
    mutation CreateReferralComment($input: CreateReferralCommentInput!) {
  createReferralComment(input: $input) {
    id
    name
  }
}
    `
export type JobSeekerCreateReferralCommentMutationFn = Apollo.MutationFunction<
  JobSeekerCreateReferralCommentMutation,
  JobSeekerCreateReferralCommentMutationVariables
>

/**
 * __useJobSeekerCreateReferralCommentMutation__
 *
 * To run a mutation, you first call `useJobSeekerCreateReferralCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerCreateReferralCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobSeekerCreateReferralCommentMutation, { data, loading, error }] = useJobSeekerCreateReferralCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobSeekerCreateReferralCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobSeekerCreateReferralCommentMutation,
    JobSeekerCreateReferralCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useMutation<
    JobSeekerCreateReferralCommentMutation,
    JobSeekerCreateReferralCommentMutationVariables
  >(JobSeekerCreateReferralCommentDocument, options)
}
export type JobSeekerCreateReferralCommentMutationHookResult = ReturnType<
  typeof useJobSeekerCreateReferralCommentMutation
>
export type JobSeekerCreateReferralCommentMutationResult =
  Apollo.MutationResult<JobSeekerCreateReferralCommentMutation>
export type JobSeekerCreateReferralCommentMutationOptions = Apollo.BaseMutationOptions<
  JobSeekerCreateReferralCommentMutation,
  JobSeekerCreateReferralCommentMutationVariables
>
export const JobSeekerReferralCommentsDocument = gql`
    query ReferralComments($slug: String!) {
  referralComments(slug: $slug) {
    id
    name
    comment
    organization
    relationship
    createdAt
    avatar
  }
}
    `

/**
 * __useJobSeekerReferralCommentsQuery__
 *
 * To run a query within a React component, call `useJobSeekerReferralCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSeekerReferralCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSeekerReferralCommentsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useJobSeekerReferralCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobSeekerReferralCommentsQuery,
    JobSeekerReferralCommentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<JobSeekerReferralCommentsQuery, JobSeekerReferralCommentsQueryVariables>(
    JobSeekerReferralCommentsDocument,
    options,
  )
}
export function useJobSeekerReferralCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSeekerReferralCommentsQuery,
    JobSeekerReferralCommentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<
    JobSeekerReferralCommentsQuery,
    JobSeekerReferralCommentsQueryVariables
  >(JobSeekerReferralCommentsDocument, options)
}
export type JobSeekerReferralCommentsQueryHookResult = ReturnType<
  typeof useJobSeekerReferralCommentsQuery
>
export type JobSeekerReferralCommentsLazyQueryHookResult = ReturnType<
  typeof useJobSeekerReferralCommentsLazyQuery
>
export type JobSeekerReferralCommentsQueryResult = Apollo.QueryResult<
  JobSeekerReferralCommentsQuery,
  JobSeekerReferralCommentsQueryVariables
>
